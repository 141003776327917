import {
  useGetCompanyInfoQuery,
  useGetRecommendationsQuery,
  useSendVideoEventMutation,
} from "../../feature/api/apiSlice";
import { PageSpinner } from "../PageSpinner";
import ReactPlayer from "react-player";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { ShareVideoForm } from "../ShareVideoForm";
import DemoContainer from "./DemoContainer";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../feature/session/sessionSlice";
import { applyRules } from "../../libraries/rule-engine/ruleEngine";

const getDateTime = () => new Date(Date.now()).toISOString().slice(0, 19).replace("T", " ");

const getTime = (seconds) => new Date(Math.round(seconds) * 1000).toISOString().substr(11, 8);

const VideoPlayer = ({ relationId, videoId, question, response, ...props }) => {
  const [progress, setProgress] = useState(0);
  const [sendVideoEvent] = useSendVideoEventMutation();
  const session = useSelector(selectCurrentUser);

  const sendEvent = async (type) => {
    console.log(`Dispatching ${type} video event`);
    const res = await sendVideoEvent({
      videoId,
      relationId,
      pageId: session.pageId,
      type,
      timestamp: getDateTime(),
      part: getTime(progress),
      module: "preview",
      question,
      response,
    });
    console.log(`Dispatching ${type} video event returned: ${JSON.stringify(res)}`);
  };

  return (
    <ReactPlayer
      width="428px"
      height="240px"
      onPlay={async () => await sendEvent("play")}
      onPause={async () => await sendEvent("pause")}
      onEnded={async () => await sendEvent("end")}
      onProgress={({ playedSeconds: progress }) => setProgress(progress)}
      {...props}
    />
  );
};

const recordingIndex = (id, recordings) => {
  return {
    id: recordings[id].id,
    companyId: recordings[id].company_id,
    salesrepId: recordings[id].salesrep_id,
    title: recordings[id].title,
    description: recordings[id].description,
    location: recordings[id].location,
    coordinates: recordings[id].coordinates,
    thumbnail: recordings[id].thumbnail,
    preview: recordings[id].preview,
  };
};

const findMatch = (mapping, response, videos, recordings) => {
  if (!mapping) return null;
  let matchedMapping = applyRules(mapping, response);

  let recommendations = [];

  if (matchedMapping.length !== 0) {
    recommendations = matchedMapping.map((it) => {
      return {
        ...recordingIndex(it.demo.value, recordings),
      };
    });
  }
  return { recommendations: recommendations };
};

const Video = ({ video, relationId, salesRepId, prospectId, question, response }) => {
  const session = useSelector(selectCurrentUser);
  const companyTemplate = session.companyTemplate;
  const buttonColor = companyTemplate.buttonBgColor !== "" ? companyTemplate.buttonBgColor : "#1E41AF";
  const buttonHoverColor = companyTemplate.buttonBgHoverColor !== "" ? companyTemplate.buttonBgHoverColor : "#1E3B8A";
  const [buttonBgColor, setButtonBgColor] = useState(buttonColor);
  const style = {
    backgroundColor: buttonBgColor,
  };

  const [openForm, setOpenForm] = useState(false);
  return (
    <div className="flex flex-row gap-x-4 my-0 pl-2">
      <ShareVideoForm
        isOpen={openForm}
        setIsOpen={setOpenForm}
        prospectId={prospectId}
        salesRepId={salesRepId}
        videoId={video.id}
      />
      <VideoPlayer
        className="flex-shrink-0"
        controls
        light
        url={video.url}
        videoId={video.id}
        relationId={relationId}
        question={question}
        response={response}
      />
      <div className="flex flex-col ml-[20px] gap-y-2 max-w-[564px] font-NunitoSans">
        <h3
          style={{
            color: companyTemplate.textColor !== "" ? companyTemplate.textColor : "#000000",
          }}
          className="m-0 video-header"
        >
          {video.title}
        </h3>
        <p
          style={{
            color: companyTemplate.textColor !== "" ? companyTemplate.textColor : "#000000",
          }}
          className="m-0 video-des"
        >
          {video.description}
        </p>
        {/*<button*/}
        {/*  className="px-6 w-24 h-10 text-lg font-NunitoSans font-bold inline-block rounded text-white"*/}
        {/*  style={style}*/}
        {/*  onMouseEnter={() => {*/}
        {/*    setButtonBgColor(buttonHoverColor);*/}
        {/*  }}*/}
        {/*  onMouseLeave={() => {*/}
        {/*    setButtonBgColor(buttonColor);*/}
        {/*  }}*/}
        {/*  onClick={() => {*/}
        {/*    setOpenForm(true);*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Share*/}
        {/*</button>*/}
      </div>
    </div>
  );
};

export const RecommendationsContainer = forwardRef(
  ({ videoRef, question, index, prospectId, relationId, salesRepId, response }, ref) => {
    const session = useSelector(selectCurrentUser);
    const formId = session.pageTemplate.form;
    const pageId = session.pageId;
    const companyId = useSelector((state) => state.session.companyId);
    const { data, isLoading, isFetching, isError, error } = useGetRecommendationsQuery({
      companyId: (session.isPreview) ? session.secondaryCompanyId : companyId,
      formId: formId,
    });
    const companyInfo = useGetCompanyInfoQuery({ companyId: companyId });
    const [videos, setVideos] = useState([]);

    useImperativeHandle(ref, () => ({
      sendRecommendedVideos: () => {
        const res = JSON.parse(JSON.stringify(response));
        delete res[question.name.trim()];
        return findMatch(data.payload.mapping, res, data.payload.videos, data.payload.recordings).recommendations;
      },
    }));

    useEffect(() => {
      if (!isLoading && !isFetching) {
        const matching = findMatch(data.payload.mapping, response, data.payload.videos, data.payload.recordings);
        if (!matching) return;
        if (index === 0) {
          return setVideos(matching.recommendations);
        }
        const fetchData = videoRef.current[index].sendRecommendedVideos();
        const videoIds = fetchData.map((el) => {
          return el.id;
        });
        if (videoIds !== undefined) {
          const filteredRecommendations = matching.recommendations.filter((el) => {
            return videoIds.indexOf(el.id) === -1;
          });
          setVideos(filteredRecommendations);
        } else {
          setVideos(matching.recommendations);
        }
      }
    }, [data, isLoading, isFetching, response]);

    if (isLoading || companyInfo.isLoading) return <PageSpinner />;
    if (isError || companyInfo.isError) return <div>{JSON.stringify(error)}</div>;

    const textColor = session.companyTemplate.textColor;

    return (
      <>
        {videos.length > 0 && (
          <div className="flex flex-col flex-wrap place-content-between gap-x-6">
            {videos.map((video, index) =>
              video.url ? (
                <Video
                  key={index}
                  video={video}
                  relationId={relationId}
                  prospectId={prospectId}
                  salesRepId={salesRepId}
                  question={question.name.trim()}
                  response={response[question.name.trim()]}
                />
              ) : (
                <div className="flex flex-col md:flex-row gap-y-[18px]" key={`sw${video.id}-${index}`}>
                  <DemoContainer
                    video={video}
                    pageId={pageId}
                    relationId={relationId}
                    question={question}
                    response={response}
                    watermark={companyInfo.data.payload[0].watermark}
                  />
                  <div className="flex flex-col md:ml-[20px] gap-y-[8px] max-w-[564px]">
                    <h3 style={{ color: textColor }} className="m-0 video-header">
                      {video.title}
                    </h3>
                    <p style={{ color: textColor }} className="m-0 video-des">
                      {video.description}
                    </p>
                  </div>
                </div>
              )
            )}
          </div>
        )}
      </>
    );
  }
);
