import "./App.css";
import Dashboard from "./components/Dashboard";
import {Provider} from "react-redux";
import store from "./Store";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

function App() {
    Sentry.init({
        dsn: "https://9074b628d2cc4a65ba33063c21e3568d@o1262621.ingest.sentry.io/6445191",
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });

    return (
        <Provider store={store}>
            <Dashboard/>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover/>
        </Provider>
    );
}

export default App;