import React from "react";
import clsx from "clsx";
// import style from "../modal/Modal.css";
import local from "./EmailVerifyRetry.module.css";
// import Footer from "../../layout/footer/Footer";

// interface Props {
//   email: string | null;
//   getStatus: () => void;
//   setSentEmail: (state: boolean) => void;
//   setRetry: (state: boolean) => void;
// }
const EmailVerifyRetry = ({ email, setSentEmail, setRetry, getStatus }) => {
  const emailSVG = (
    <svg width="66" height="54" viewBox="0 0 66 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 2.5H47.5C50.25 2.5 52.5 4.75 52.5 7.5V37.5C52.5 40.25 50.25 42.5 47.5 42.5H7.5C4.75 42.5 2.5 40.25 2.5 37.5V7.5C2.5 4.75 4.75 2.5 7.5 2.5Z"
        fill="#0071F2"
        stroke="#0071F2"
        stroke-width="5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M47.5 7.5L27.5 25L7.5 7.5"
        stroke="white"
        stroke-width="5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="54" cy="42" r="12" fill="#D37474" />
      <path d="M59 37L49 47" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M49 37L59 47" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
  return (
    <div className={local.card}>
      <div className={local.content}>
        <div className={local.icon}>{emailSVG}</div>
        <div className={local.title}>Email address could not be verified</div>
        <hr className={local.horizontal} />
        <div className={local.description}>
          <p>Click the link in the email message to proceed further.</p>
          <p>Check the spam folder if you can't find it.</p>
        </div>
        <button className={local.button} onClick={getStatus} type="button">
          Start
        </button>
        <p className={local.return} style={{ marginBottom: "13px" }}>
          Wrong Email? Please{" "}
          <span
            className={local.link}
            onClick={() => {
              setSentEmail(false);
              setRetry(false);
            }}
          >
            re-enter
          </span>{" "}
          your email address
        </p>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default EmailVerifyRetry;
