import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";

export const TagTypes = {
  RELATION: "relation",
  SHARED: "shared",
  PAGE_TEMPLATE: "page_template",
  COMPANY_TEMPLATE: "company_template",
  RESPONSE: "response",
  FORM: "form",
  PAGE: "page",
  PROSPECT_INFO: "prospect_information",
  WIDGET: "widget",
  RECORDING: "recording",
  VERIFICATION: "verification",
};

export const apiSlice = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_SERVER}/web`,
        prepareHeaders: (headers, {getState}) => {
            const token = getState()?.session?.token;
            if (token) headers.set("token", token);
            return headers;
        },
    }),
    tagTypes: Object.values(TagTypes),
    endpoints: (builder) => ({
        getRelation: builder.query({
            query: ({prospectId, pageId, salesRepId, companyId}) =>
                `/prospect/${prospectId}/relation/${pageId}/${salesRepId}/${companyId}`,
            providesTags: [TagTypes.RELATION],
        }),
        getForm: builder.query({
            query: ({formId, relationId}) =>
                `/prospect/form/${formId}/${relationId}`,
            providesTags: [TagTypes.FORM],
        }),
        getPage: builder.query({
            query: ({pageId}) => `/prospect/page/${pageId}/`,
            providesTags: [TagTypes.PAGE],
        }),
        getVerificationStatus: builder.query({
            query: ({ email }) => `/widget/status/${email}`,
            providesTags: [TagTypes.VERIFICATION],
        }),
        getIntroVideo: builder.query({
            query: ({pageId, companyId}) =>
                `/prospect/intro/video/${pageId}/${companyId}/`,
        }),
        getResponse: builder.query({
            query: ({prospectId, relationId, formId}) =>
                `/prospect/${prospectId}/relation/${relationId}/form/${formId}/response`,
            providesTags: [TagTypes.RESPONSE],
        }),
        getRecommendations: builder.query({
            query: ({companyId, formId}) => ({
                url: `/prospect/recommendations/${companyId}/${formId}`,
            }),
            providesTags: [TagTypes.RELATION],
        }),
        getRecording: builder.query({
            query: ({
                        recordingId,
                        token,
                        companyId
                    }) => `/prospect/recording/public/${recordingId}/${token}/${companyId}`,
            providesTags: [TagTypes.RECORDING],
        }),
        getAllRecommendations: builder.query({
            query: ({companyId}) => ({
                url: `/prospect/recommendations/${companyId}/`,
            }),
            providesTags: [TagTypes.RELATION],
        }),
        login: builder.query({
            query: (token) => `/prospect/session/login/${token}/`,
            providesTags: [],
        }),
        getSharedVideos: builder.query({
            query: ({prospectId}) => `/prospect/sharing/video/${prospectId}`,
            providesTags: [TagTypes.SHARED],
        }),
        getProspectNames: builder.query({
            query: ({videoId, prospectId}) =>
                `/prospect/sharing/name/${videoId}/${prospectId}`,
            providesTags: [],
        }),
        getCompanyTemplate: builder.query({
            query: ({companyId}) => `/prospect/setting/company/${companyId}`,
            providesTags: [TagTypes.COMPANY_TEMPLATE],
        }),
        getPageTemplate: builder.query({
            query: ({pageId}) => `/prospect/setting/page/${pageId}`,
            providesTags: [TagTypes.PAGE_TEMPLATE],
        }),
        getLogo: builder.query({
            query: ({companyId}) => `/prospect/setting/logo/${companyId}`,
            providesTags: [TagTypes.COMPANY_TEMPLATE],
        }),
        getPageRules: builder.query({
            query: ({companyId, salesRepId, websiteId}) =>
                `prospect/rule/page/${salesRepId}/${websiteId}/${companyId}`,
            providesTags: [],
        }),
        getPageIdentifiers: builder.query({
            query: ({companyId, salesRepId, pageId, prospectId}) =>
                `prospect/identifier/page/${pageId}/${salesRepId}/${prospectId}/${companyId}`,
            providesTags: [],
        }),
        getEmailsBlockList: builder.query({
            query: (initialPost) =>
                "prospect/company/emails/" + initialPost.companyId,
            providesTags: [],
        }),
        getCompanyInfo: builder.query({
            query: ({companyId}) => `widget/company/${companyId}`,
            providesTags: [],
        }),
        getPreview: builder.query({
            query: ({id}) => `preview/${id}`,
            providesTags: [],
        }),
        shareVideo: builder.mutation({
            query: ({salesRepId, prospectId, name, email, videoId}) => ({
                url: `/prospect/sharing/video`,
                method: "POST",
                body: {
                    salesRepId: salesRepId,
                    prospectId: prospectId,
                    name: name,
                    email: email,
                    videoId: videoId,
                },
            }),
            invalidatesTags: [TagTypes.SHARED],
        }),
        sendWidgetToken: builder.mutation({
            query: ({token}) => ({
                url: `/widget/verify`,
                method: "POST",
                body: {
                    token: token,
                },
            }),
            invalidatesTags: [TagTypes.WIDGET],
        }),
        sendEmaiVerification: builder.mutation({
            query: ({ email, companyId, checkTwoFactor = false }) => ({
                url: `/widget/validate`,
                method: "POST",
                body: { email, companyId, checkTwoFactor },
            }),
            invalidatesTags: [TagTypes.VERIFICATION],
        }),
        sendResponse: builder.mutation({
            query: ({prospectId, relationId, formId, answers}) => ({
                url: `/prospect/${prospectId}/relation/${relationId}/form/${formId}`,
                method: "POST",
                body: {
                    answers: answers,
                },
            }),
            invalidatesTags: [TagTypes.RESPONSE],
        }),
        createRelationship: builder.mutation({
            query: (initialPost) => ({
                url: "/prospect/session/website/link",
                method: "POST",
                body: {
                    name: initialPost.name,
                    email: initialPost.email,
                    pageId: initialPost.pageId,
                    salesrepId: initialPost.salesRepId,
                    companyId: initialPost.companyId,
                    dateCreated: initialPost.dateCreated
                },
            }),
        }),
        sendMail: builder.mutation({
            query: ({url, name, email}) => ({
                url: `/prospect/mail/send/`,
                method: "POST",
                body: {
                    url: url,
                    name: name,
                    email: email,
                },
            }),
            invalidatesTags: [],
        }),
        sendProspectInfo: builder.mutation({
            query: ({pageId, companyId, prospectId, info}) => ({
                url: `prospect/page/information`,
                method: "POST",
                body: {
                    pageId: pageId,
                    companyId: companyId,
                    prospectId: prospectId,
                    info: info,
                },
            }),
        }),
        sendVideoEvent: builder.mutation({
            query: ({
                        videoId,
                        pageId,
                        module,
                        question,
                        response,
                        relationId,
                        type,
                        timestamp,
                        part,
                    }) => ({
                url: `/prospect/analytics/video/${videoId}/event/`,
                method: "POST",
                body: {
                    relationship_id: relationId,
                    page_id: pageId,
                    type: type,
                    timestamp: timestamp,
                    part: part,
                    module,
                    question: question ? JSON.stringify(question) : null,
                    answer: response ? JSON.stringify(response) : null,
                },
            }),
            invalidatesTags: [],
        }),
        sendRecordingEvent: builder.mutation({
            query: ({
                        recordingId,
                        pageId,
                        module,
                        question,
                        response,
                        relationId,
                        type,
                        timestamp,
                    }) => ({
                url: `/prospect/analytics/recording/${recordingId}/event/`,
                method: "POST",
                body: {
                    relationship_id: relationId,
                    page_id: pageId,
                    type: type,
                    timestamp: timestamp,
                    module,
                    question: question ? JSON.stringify(question) : null,
                    answer: response ? JSON.stringify(response) : null,
                },
            }),
            invalidatesTags: [],
        }),
        sendPageViewEvent: builder.mutation({
            query: ({
                        pageId,
                        prospectId,
                        timestamp,
                    }) => ({
                url: `/prospect/analytics/page/${pageId}/views/${prospectId}`,
                method: "POST",
                body: {
                    timestamp: timestamp,
                },
            }),
            invalidatesTags: [],
        }),
        unsubscribeSummaryWeekly: builder.mutation({
            query: ({salesrepId}) => ({
                url: `/scheduled/summary/weekly/unsubscribe/${salesrepId}`,
                method: "POST",
            }),
            invalidatesTags: [],
        }),
    }),
});

export const {
    useLoginQuery,
    useGetRelationQuery,
    useGetFormQuery,
    useGetPageQuery,
    useGetVerificationStatusQuery,
    useGetIntroVideoQuery,
    useGetResponseQuery,
    useGetRecommendationsQuery,
    useGetRecordingQuery,
    useGetAllRecommendationsQuery,
    useGetSharedVideosQuery,
    useGetProspectNamesQuery,
    useGetCompanyTemplateQuery,
    useGetPageTemplateQuery,
    useGetLogoQuery,
    useGetPageRulesQuery,
    useGetPageIdentifiersQuery,
    useGetEmailsBlockListQuery,
    useGetCompanyInfoQuery,
    useGetPreviewQuery,
    useShareVideoMutation,
    useSendResponseMutation,
    useSendWidgetTokenMutation,
    useSendEmaiVerificationMutation,
    useCreateRelationshipMutation,
    useSendMailMutation,
    useSendProspectInfoMutation,
    useSendVideoEventMutation,
    useSendRecordingEventMutation,
    useSendPageViewEventMutation,
    useUnsubscribeSummaryWeeklyMutation,
} = apiSlice;
