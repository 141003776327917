export const applyOperation = (LHS, RHS, operation, type) => {
  switch (type) {
    case "string":
      return stringOperations(LHS, RHS, operation);
    case "list":
      return listOperations(LHS, RHS, operation);
    case "modal":
      return modalOperations(LHS, RHS, operation);
    default:
      console.log("Unknown Type", type);
  }
};

const stringOperations = (LHS, RHS, operation) => {
  switch (operation) {
    case "===":
      return equal(LHS, RHS);
    case "!==":
      return notEqual(LHS, RHS);
    case ">":
      return isGreaterThan(LHS, RHS);
    case "<":
      return isSmallerThan(LHS, RHS);
    case ">==":
      return isGreaterThanEqualTo(LHS, RHS);
    case "<==":
      return isSmallerThanEqualTo(LHS, RHS);
    case "includes":
      return includes(LHS, RHS);
    default:
      console.log("operator not supported", operation);
  }
};

const listOperations = (LHS, RHS, operation) => {
  switch (operation) {
    case "equals":
      return equals(LHS, RHS);
    case "includes":
      return listIncludes(LHS, RHS);
    case "includes all":
      return listIncludesAll(LHS, RHS);
    case "not includes":
      return listNotIncludesAll(LHS, RHS);
    case "modal includes":
      return modalListIncludes(LHS, RHS);
    case "modal not includes":
      return modalListNotIncludes(LHS, RHS);
    default:
      console.log("operator not supported", operation);
  }
};

const modalOperations = (LHS, RHS, operation) => {
  switch (operation) {
    case "modal includes":
      return modalListIncludes(LHS, RHS);
    case "modal not includes":
      return modalListNotIncludes(LHS, RHS);
    default:
      console.log("operator not supported", operation);
  }
};

const equal = (LHS, RHS) => {
  return LHS === RHS;
};

const notEqual = (LHS, RHS) => {
  return LHS !== RHS;
};

const isGreaterThan = (LHS, RHS) => {
  return LHS > RHS;
};

const isSmallerThan = (LHS, RHS) => {
  return LHS < RHS;
};

const isGreaterThanEqualTo = (LHS, RHS) => {
  return LHS >= RHS;
};

const isSmallerThanEqualTo = (LHS, RHS) => {
  return LHS <= RHS;
};

const includes = (LHS, RHS) => {
  return LHS.includes(RHS);
};

//List Only functions
const equals = (LHS, RHS) => {
  if (LHS.length === RHS.length) {
    let sortedLHS = LHS.slice().sort();
    let sortedRHS = RHS.slice().sort();
    return sortedLHS.toString() === sortedRHS.toString();
  }
  return false;
};

const listIncludes = (LHS, RHS) => {
  let result = false;
  LHS.forEach((it) => {
    if (RHS.includes(it)) {
      result = true;
    }
  });
  return result;
};

const listIncludesAll = (LHS, RHS) => {
  let currentArray = [...RHS];
  LHS.forEach((it) => {
    currentArray = currentArray.filter((el) => el !== it);
  });
  return currentArray.length <= 0;
};

const listNotIncludesAll = (LHS, RHS) => {
  let result = true;
  LHS.forEach((it) => {
    if (RHS.includes(it)) {
      result = false;
    }
  });
  return result;
};

const modalListIncludes = (LHS, RHS) => {
  let result = false;
  console.log(RHS);
  RHS.forEach((it) => {
    if (LHS.includes(it)) {
      result = true;
    }
  });
  return result;
};

const modalListNotIncludes = (LHS, RHS) => {
  let result = true;
  RHS.forEach((it) => {
    if (LHS.includes(it)) {
      result = false;
    }
  });
  return result;
};