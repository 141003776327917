import React from "react";
import ReactDom from "react-dom";

const MODAL_STYLES = {
    width: "500px",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    padding: "50px",
    zIndex: 1000,
};

const OVERLAY_STYLES = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, .7)",
    zIndex: 1000,
};

const Modal = ({ open, children, onClose }) => {
    if (!open) return null;
    return ReactDom.createPortal(
        <>
            <div style={OVERLAY_STYLES} />
            <div style={MODAL_STYLES}>
                <button
                    className="rounded-bl-lg bg-red-600 top-0 right-0 h-10 w-10 border-b-4 border-l-4 absolute"
                    onClick={onClose}
                >
                    X
                </button>
                {children}
            </div>
        </>,
        document.getElementById("portal")
    );
}

export default Modal;
