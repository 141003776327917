import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Session } from "./Session";
import "survey-react/modern.css";
import { SessionContainer } from "./containers/SessionContainer";
import { SharedVideosContainer } from "./containers/SharedVideosContainer";
import TeaserContainer from "./containers/TeaserContainer";
import { PageNotFound } from "./containers/PageNotFound";
import { PreviewSession } from "./PreviewSession";
import WidgetVerify from "./WidgetVerify";
import UrlBasedVideo from "./UrlBasedVideo";
import { RecordingContainer } from "./containers/RecordingContainer";
import { LinkEmbedPageContainer } from "./containers/LinkEmbedPageContainer";
import { CYAPreviewContainer } from "./containers/CYAPreviewContainer";
import { HeroPreviewContainer } from "./containers/HeroPreviewContainer";
import { SoloPreviewContainer } from "./containers/SoloPreviewContainer";
import { DemoPreviewContainer } from "./containers/DemoPreviewContainer";
import { WeeklySummaryUnsubContainer } from "./containers/WeeklySummaryUnsubContainer";

const Dashboard = () => {
  return (
    <BrowserRouter>
      <div className="flex flex-row">
        <div className="flex-1 min-h-screen">
          <Switch>
            {/*<Route exact path="/:relationid" component={Launch}/>*/}
            <Route path="/prototype/urlbased" component={UrlBasedVideo} />
            <Route path="/:token/login/" component={Session} />
            <Route path="/:token/website/login/:salesRepId" component={Session} />
            <Route path="/:token/unlisted/:unlisted" component={Session} />
            <Route path="/:token/preview/:pageId/:companyId/:unlisted" component={PreviewSession} />
            <Route path="/:token/recording/:id/:companyId" component={RecordingContainer} />
            <Route path="/:token/page/widget/:widgetType/:id/:companyId" component={LinkEmbedPageContainer} />
            <Route path="/:companyId/:token/widget/verify" component={WidgetVerify} />
            <Route path="/preview/solo" component={SoloPreviewContainer} />
            <Route path="/preview/hero" component={HeroPreviewContainer} />
            <Route path="/preview/cyoa" component={CYAPreviewContainer} />
            <Route path="/preview/demo" component={DemoPreviewContainer} />
            <Route path="/unsubscribe/:id/weekly/summary" component={WeeklySummaryUnsubContainer} />
            <Route path="/video/shared" component={SharedVideosContainer} />
            <Route path="/signup/prospect" component={TeaserContainer} />
            <Route path="/error/" component={PageNotFound} />
            <Route path="/" component={SessionContainer} />
          </Switch>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default Dashboard;
