import {
    useGetCompanyTemplateQuery, useGetLogoQuery,
    useGetPageTemplateQuery,
    useLoginQuery
} from "../feature/api/apiSlice";
import {Redirect, useLocation, useParams} from "react-router-dom";
import {setCredentials, setLogo, setPreview, setTemplates} from "../feature/session/sessionSlice";
import {useDispatch} from "react-redux";
import React from "react";
import queryString from "query-string";
import {ErrorView} from "./ErrorView";
import {PageSpinner} from "./PageSpinner";

const GetStyleData = ({payload, companyId, pageId}) => {
    const companyTemplate = useGetCompanyTemplateQuery({companyId: companyId})
    const pageTemplate = useGetPageTemplateQuery({pageId: pageId})
    const companyLogo = useGetLogoQuery({companyId: companyId });
    const dispatch = useDispatch();


    if (pageTemplate.isError || companyTemplate.isError || companyLogo.isError) {
        return <ErrorView title="Error loading necessary information" data={""}/>
    }

    if (pageTemplate.isLoading || companyTemplate.isLoading || companyLogo.isLoading) {
        return <PageSpinner/>
    }

    if (companyLogo.data.payload[0].recent_method === "url") {
        if (
            companyLogo.data.payload[0].logo_url !== null &&
            companyLogo.data.payload[0].logo_url !== ""
        ) {
            dispatch(setLogo({companyLogo: companyLogo.data.payload[0].logo_url}));
        }
    }
    if (companyLogo.data.payload[0].recent_method === "image") {
        if (
            companyLogo.data.payload[0].logo_image !== null &&
            companyLogo.data.payload[0].logo_image !== ""
        ) {
            dispatch(setLogo({companyLogo: companyLogo.data.payload[0].logo_image}));
        }
    }

    dispatch(setTemplates({
        companyTemplate: JSON.parse(companyTemplate.data.payload[0].website_style),
        pageTemplate: JSON.parse(pageTemplate.data.payload.pageStyle[0].page_style),
        pageType: pageTemplate.data.payload.pageType[0].type,
    }));

    if (payload.id === null) {
        return <Redirect to="/signup/prospect"/>
    }

    return <Redirect to="/"/>
}

export const PreviewSession = () => {
    const location = useLocation();
    const dispatch = useDispatch()
    const {token, companyId, pageId} = useParams();
    const loginQuery = useLoginQuery(token);

    if (loginQuery.isLoading) return <PageSpinner/>
    if (loginQuery.isError) return <div>{JSON.stringify(loginQuery.error)}</div>
    if (loginQuery.data.status !== "success")
        return <ErrorView title="Error loading session information" data={loginQuery.data} />

    dispatch(setCredentials({
        id: loginQuery.data.payload.id,
        name: loginQuery.data.payload.name,
        email: loginQuery.data.payload.email,
        token: loginQuery.data.payload.token,
        salesRepId: loginQuery.data.payload.salesRepId,
        pageId: loginQuery.data.payload.pageId,
        companyId: loginQuery.data.payload.companyId,
        companyName: loginQuery.data.payload.companyName,
        unlisted: true,
    }))
    dispatch(setPreview({
        isPreview: true,
        secondaryCompanyId: companyId,
    }))

    const parsed = queryString.parse(location.search);
    if (parsed.video === "true") {
        return <Redirect to="/video/shared"/>
    }
    return (
        <GetStyleData payload={loginQuery.data.payload} companyId={companyId} pageId={pageId}/>
    )
}