import { useGetFormQuery, useGetResponseQuery } from "../../../feature/api/apiSlice";
import React, { useEffect, useRef, useState } from "react";
import "./ProgressiveFormQueryContainer.css";
import { PageSpinner } from "../../PageSpinner";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../feature/session/sessionSlice";
import { IndivSurvey } from "./IndivSurvey";
import { StandardPreviewContainer } from "../StandardPreviewContainer";
import {applyTemplate} from "../../../libraries/template-engine/templateEngine";

export const ProgressiveFormQueryContainer = ({
  prospectId,
  relationId,
  salesRepId,
  readOnly,
  toggleAllQuestionsAnswered,
}) => {
  const session = useSelector(selectCurrentUser);
  const formId = session.pageTemplate.form;
  const { data, isLoading, isError, error } = useGetFormQuery({ formId: formId, relationId: relationId });
  const response = useGetResponseQuery({
    prospectId: prospectId,
    relationId: relationId,
    formId: formId,
  });
  const [revealQuestions, setRevealQuestions] = useState([]);
  const [savedRes, setSavedRes] = useState(null);
  const videoRef = useRef([]);

  useEffect(() => {
    if (!isLoading && !response.isLoading && session.pageType !== "standard") {
      if (data?.payload?.formjson) {
        const questionArr = Array(JSON.parse(data.payload.formjson).questions.length + 1);
        questionArr.fill(false, 0, questionArr.length);
        questionArr[0] = true;
        if (response.data.payload.length !== 0 && !session.unlisted) {
          setSavedRes(JSON.parse(response.data.payload[0].answers));
          const responseLength = Object.keys(JSON.parse(response.data.payload[0].answers)).length;
          if (session.pageTemplate.previewType !== "Standard" || session.pageTemplate !== undefined) {
            for (let i = 1; i <= responseLength; i++) {
              questionArr[i] = true;
            }
          }
        }
        setRevealQuestions(questionArr);
      }
    }
  }, [data, isLoading, response.isLoading]);

  const isAllQuestionsAnswered = revealQuestions.every((question) => question === true);
  toggleAllQuestionsAnswered(isAllQuestionsAnswered);

  if (isLoading || response.isLoading) return <PageSpinner/>;
  if (isError || response.isError) return <div>{JSON.stringify(error)}</div>;

  const borderColor = session.companyTemplate.borderColor;

  return (
    <div className="flex flex-col">
      <div style={{ borderColor }} className="py-[18px] border-[1.5px] rounded-t-lg">
        <p className="ml-10 font-NunitoSans font-bold text-lg">{
          applyTemplate(session.pageTemplate.previewMessage, session.pageIdentifiers)
        }</p>
      </div>

      <div
        style={{ borderColor }}
        className="rounded-b-lg border-b-[1.5px] border-r-[1.5px] border-l-[1.5px] px-[74px] pb-10"
      >
        {session.pageType === "standard" ? (
          <div style={{ borderColor, paddingTop: "60px" }}>
            <StandardPreviewContainer prospectId={prospectId} relationId={relationId} salesRepId={salesRepId} />
          </div>
        ) : (
          <div>
            {(data?.payload?.formjson) ?
                JSON.parse(data.payload.formjson).questions.map((question, index) => {
                  return (
                      <IndivSurvey
                          key={index}
                          ref={(el) => (videoRef.current[index] = el)}
                          videoRef={videoRef}
                          data={question}
                          index={index}
                          revealQuestions={revealQuestions}
                          setRevealQuestions={setRevealQuestions}
                          form={JSON.parse(data.payload.formjson)}
                          savedRes={savedRes}
                          setSavedRes={setSavedRes}
                          prospectId={prospectId}
                          relationId={relationId}
                          salesRepId={salesRepId}
                          formId={formId}
                      />
                  );
                })
                :
                false
            }
          </div>
        )}
      </div>
    </div>
  );
};
