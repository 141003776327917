import React, { Fragment } from "react";
import { createPortal } from "react-dom";
import styles from "./Modal.module.css";

const Modal = ({ open, websiteStyle, fontName, children, onClose }) => {
  if (!open) {
    return null;
  }
  return createPortal(
    <Fragment>
      <div className={styles.overlay} onClick={onClose} />
      <div
        className={styles.modal}
        style={{
          "--buttonBgColor": websiteStyle.buttonBgColor ?? "#1e41af",
          "--buttonBgHoverColor": websiteStyle.buttonBgHoverColor ?? "#1e41afe6",
          "--textColor": websiteStyle.textColor ?? "#000000",
          "--fontFamily": fontName ?? websiteStyle.fontName,
        }}
      >
        {children}
      </div>
    </Fragment>,
    document.body
  );
};

export default Modal;
