import React, { Fragment, useEffect, useState } from "react";
import { useGetVerificationStatusQuery, useSendEmaiVerificationMutation } from "../feature/api/apiSlice";
import EmailError from "./containers/email-error/EmailError";
import EmailInput from "./containers/email-input/EmailInput";
import EmailVerifyRetry from "./containers/email-verify-retry/EmailVerifyRetry";
import EmailVerify from "./containers/email-verify/EmailVerify";
import Modal from "./containers/modal/Modal";

export const Gating = ({ session, isVerified, setIsVerified }) => {
  const companyTemplate = session.companyTemplate;
  const pageTemplate = session.pageTemplate;
  const emailBlockList = session.emailBlockList;
  const companyId = session.companyId;
  const intendedRecipient = session.email;

  const [skipVerificationStausCheck, setSkipVerificationStatusCheck] = useState(true);
  const [sentEmail, setSentEmail] = useState(false);
  const [retry, setRetry] = useState(false);
  const [errorPrompt, setErrorPrompt] = useState(false);
  const [email, setEmail] = useState();
  const [sendEmail] = useSendEmaiVerificationMutation();

  const {
    data: statusData,
    error: statusError,
    isLoading: isLoadingStatus,
  } = useGetVerificationStatusQuery({ email }, { skip: skipVerificationStausCheck });

  useEffect(() => {
    if (checkACookieExists()) {
      const token = document.cookie
        .split(";")
        .find((item) => item.trim().startsWith("zerostoken="))
        .split("=")[1];
      setCookie(token);
      setIsVerified(true);
    }
  }, []);

  useEffect(() => {
    if (statusData?.status === "success") {
      console.log(statusData);
      if (statusData.payload?.token) {
        setCookie(statusData.payload.token);
        setRetry(false);
        setIsVerified(true);
        return;
      }
      setSkipVerificationStatusCheck(true);
      setRetry(true);
    }
  }, [skipVerificationStausCheck, statusData]);

  const checkACookieExists = () => {
    return document.cookie.split(";").some((item) => item.trim().startsWith("zerostoken="));
  };

  const setCookie = (token) => {
    const date = new Date();
    date.setDate(date.getDate() + 30);
    document.cookie = `zerostoken=${token}; expires=${date.toUTCString()};`;
    //   setCookieInfo({ token });
  };

  const getStatus = () => {
    if (!email) {
      // Add form validation
      console.log("Empty email not allowed");
      return;
    }
    setSkipVerificationStatusCheck(false);
  };

  const validateEmail = (enteredEmail) => {
    return String(enteredEmail)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const submitEmailHandler = async (skip) => {
    if (skip) {
      setIsVerified(true);
      return;
    }
    if (pageTemplate.settings.customVerification) {
      if (email) {
        if (!validateEmail(email)) {
          setErrorPrompt(true);
          return;
        }

        /* Check if given email belongs to a blocklist */
        if (pageTemplate.settings.checkBlocklist) {
          if (emailBlockList?.includes(email.split("@")[1])) {
            setErrorPrompt(true);
            return;
          }
        }

        /* Check if given email belongs to the intended recipient */
        if (pageTemplate.settings.checkIntended) {
          if (email !== intendedRecipient) {
            setErrorPrompt(true);
            return;
          }
        }

        /* Short circuit if next checks are not to be run */
        if (!pageTemplate.settings.checkDomain && !pageTemplate.settings.checkTwoFactor) {
          setIsVerified(true);
          return;
        }

        /* Check if given email belongs to a real domain */
        if (pageTemplate.settings.checkDomain) {
          try {
            const res = await sendEmail({
              email,
              companyId,
              checkTwoFactor: pageTemplate.settings.checkTwoFactor,
            }).unwrap();
            if (res.status === "success") {
              if (res.payload && res.payload.status && res.payload.status === "invalid") {
                setErrorPrompt(true);
                return;
              }
              if (pageTemplate.settings.checkTwoFactor) {
                setSentEmail(true);
                return;
              } else {
                setIsVerified(true);
              }
            } else {
              setErrorPrompt(true);
            }
          } catch (err) {
            console.log("Couldn't connect to database");
          }
        }
      }
    } else {
      // TODO: Store email somewhere (could be malformed)
      setIsVerified(true);
      return;
    }
  };

  return (
    <Modal open={true} websiteStyle={companyTemplate} onClose={null}>
      <Fragment>
        <img
          style={{ filter: "blur(0.35rem)", maxWidth: "fit-content" }}
          crossOrigin="anonymous"
          src={`https://zerosdemostorage.azureedge.net/videos/Blurred image.jpg`}
          alt="Zeros Demo"
        ></img>
        {sentEmail ? (
          retry ? (
            <EmailVerifyRetry email={email} getStatus={getStatus} setSentEmail={setSentEmail} setRetry={setRetry} />
          ) : (
            <EmailVerify email={email} getStatus={getStatus} setSentEmail={setSentEmail} />
          )
        ) : errorPrompt ? (
          <EmailError errorPrompt={errorPrompt} setErrorPrompt={setErrorPrompt} />
        ) : (
          <EmailInput
            verificationContent={pageTemplate.verificationContent}
            emailRequired={pageTemplate.settings.isRequired}
            email={email}
            setEmail={setEmail}
            sendEmailVerification={(skip = false) => submitEmailHandler(skip)}
          />
        )}
      </Fragment>
    </Modal>
  );
};
