import {
    useGetCompanyTemplateQuery, useGetLogoQuery, useGetPageIdentifiersQuery,
    useGetPageQuery, useGetPageRulesQuery,
    useGetPageTemplateQuery, useGetRelationQuery,
    useLoginQuery, useSendPageViewEventMutation
} from "../feature/api/apiSlice";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { PageSpinner } from "./PageSpinner";
import { setCredentials, setLogo, setPageId, setTemplates } from "../feature/session/sessionSlice";
import { useDispatch } from "react-redux";
import { ErrorView } from "./ErrorView";
import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { applyRules } from "../libraries/rule-engine/ruleEngine";

const ApplyPageRules = ({ payload, queryParams, salesRepId }) => {
  const { data, isLoading, isError } = useGetPageRulesQuery({
    salesRepId: parseInt(salesRepId),
    websiteId: payload.salesRepId,
    companyId: payload.companyId,
  });
  const dispatch = useDispatch();
  const modifiedPayload = JSON.parse(JSON.stringify(payload));

  if (salesRepId === undefined) return <Redirect to="/error/" />;
  if (isLoading) return <PageSpinner />;
  if (isError) return <div>Error connecting to Database</div>;

  if (data.status === "error") {
    return <div>Error fetching information from Database</div>;
  }

  if (data.payload.length !== 0) {
    if (data.payload[0].rules !== null) {
      const rulesArray = JSON.parse(data.payload[0].rules);
      if (rulesArray.length !== 0) {
        const result = applyRules(rulesArray, queryParams);
        if (result.length !== 0) {
          modifiedPayload.pageId = result[0];
          dispatch(
            setPageId({
              pageId: result[0],
            })
          );
        }
      }
    }
  }

  return <VerifyPage payload={modifiedPayload} />;
};

const SendPageEvent = ({ payload }) => {
  const [pageViewEvent] = useSendPageViewEventMutation();

  useEffect(() => {
    try {
      pageViewEvent({
        pageId: payload.pageId,
        prospectId: payload.id,
        timestamp: new Date().toISOString().slice(0, 19).replace("T", " "),
      });
    } catch (e) {
      console.log("Unable to send event");
    }
  }, []);

  return <GetStyleData payload={payload} />;
};

const VerifyPage = ({ payload }) => {
  const { data, isLoading, isError } = useGetPageQuery({ pageId: payload.pageId });
  const { data: relationData, isLoading: relationIsLoading, isError: relationIsError } = useGetRelationQuery({ pageId: payload.pageId, prospectId: payload.id, salesRepId: payload.salesRepId, companyId: payload.companyId });

  if (isLoading || relationIsLoading) return <PageSpinner />;
  if (isError || relationIsError) return <div>Error connecting to Database</div>;

  if (data.status === "error" || relationData.status === "error") {
    return <div>Error fetching information from Database</div>;
  }

  if (data.payload.live.data[0] !== 1) {
    return <Redirect to="/error/" />;
  }

  if (payload.id) {
      if (relationData.payload.length === 0) {
          return <Redirect to="/error/" />;
      }
  }

  return <SendPageEvent payload={payload} />;
};

const GetStyleData = ({payload}) => {
    const companyTemplate = useGetCompanyTemplateQuery({ companyId: payload.companyId })
    const pageTemplate = useGetPageTemplateQuery({ pageId: payload.pageId })
    const companyLogo = useGetLogoQuery({ companyId: payload.companyId });
    const pageIdentifiers = useGetPageIdentifiersQuery({ companyId: payload.companyId, salesRepId: payload.salesRepId, pageId: payload.pageId, prospectId: payload.id })
    const dispatch = useDispatch();

    if (pageTemplate.isError || companyTemplate.isError || companyLogo.isError || pageIdentifiers.isError) {
        return <ErrorView title="Error loading necessary information" data={""}/>
    }

    if (pageTemplate.isLoading || companyTemplate.isLoading || companyLogo.isLoading || pageIdentifiers.isLoading) {
        return <PageSpinner/>
    }

    if (companyLogo.data.payload[0].recent_method === "url") {
        if (
            companyLogo.data.payload[0].logo_url !== null &&
            companyLogo.data.payload[0].logo_url !== ""
        ) {
            dispatch(setLogo({companyLogo: companyLogo.data.payload[0].logo_url}));
        }
    }
    if (companyLogo.data.payload[0].recent_method === "image") {
        if (
            companyLogo.data.payload[0].logo_image !== null &&
            companyLogo.data.payload[0].logo_image !== ""
        ) {
            dispatch(setLogo({companyLogo: companyLogo.data.payload[0].logo_image}));
        }
    }

    dispatch(setTemplates({
        companyTemplate: JSON.parse(companyTemplate.data.payload[0].website_style),
        pageTemplate: JSON.parse(pageTemplate.data.payload.pageStyle[0].page_style),
        emailBlockList: JSON.parse(companyTemplate.data.payload[0].email_block_list),
        pageType: pageTemplate.data.payload.pageType[0].type,
        pageIdentifiers: (pageIdentifiers.data.payload.length !== 0) ? JSON.parse(pageIdentifiers.data.payload[0].mapping_data) : {}
    }));

  if (payload.id === null) {
    return <Redirect to="/signup/prospect"/>;
  }

  return <Redirect to="/"/>;
};

export const Session = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { token, unlisted, salesRepId } = useParams();
  const loginQuery = useLoginQuery(token);

  if (loginQuery.isLoading) return <PageSpinner />;
  if (loginQuery.isError) return <div>{JSON.stringify(loginQuery.error)}</div>;
  if (loginQuery.data.status !== "success")
    return <ErrorView title="Error loading session information" data={loginQuery.data} />;

  dispatch(
    setCredentials({
      id: loginQuery.data.payload.id,
      name: loginQuery.data.payload.name,
      email: loginQuery.data.payload.email,
      token: loginQuery.data.payload.token,
      salesRepId: loginQuery.data.payload.salesRepId,
      pageId: loginQuery.data.payload.pageId,
      companyId: loginQuery.data.payload.companyId,
      companyName: loginQuery.data.payload.companyName,
      unlisted: unlisted === "demo",
    })
  );

  const queryParams = queryString.parse(location.search);

  if (queryParams.video === "true") {
    return <Redirect to="/video/shared" />;
  }

  if (loginQuery.data.payload.id !== null) {
    return <VerifyPage payload={loginQuery.data.payload} />;
  } else {
    return <ApplyPageRules payload={loginQuery.data.payload} queryParams={queryParams} salesRepId={salesRepId} />;
  }
};
