export const isUndefined = (LHS, RHS) => {
    return LHS === undefined || RHS === undefined;
}

export const prepareInput = (data, type, side) => {
    if (data === undefined) {
        return data;
    }
    if (type === undefined) {
        return data.toString().trim().toLowerCase();
    }

    if (side === "LHS") {
        switch (type) {
            case "string":
                return sanitizedString(data);
            case "list":
                return sanitizedList(!Array.isArray(data) ? [data] : data);
            case "modal":
                return sanitizedString(data);
            default:
                console.log("Unknown Type", type);
        }
    }

    if (side === "RHS") {
        switch (type) {
            case "string":
                return sanitizedString(data);
            case "list":
                return sanitizedList(data);
            case "modal":
                return sanitizedList(data);
            default:
                console.log("Unknown Type", type);
        }
    }
}

const sanitizedString = (data) => {
    return data.toString().trim().toLowerCase();
}

const sanitizedList = (data) => {
    const list = [];
    data.forEach((it) => {
        list.push(sanitizedString(it));
    })
    return list;
}