import React, { useEffect } from "react";
import InnerHTML from "dangerously-set-html-content";
import { uuid4 } from "@sentry/utils";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../feature/session/sessionSlice";
import { useState } from "react";

const DemoContainer = ({ video, pageId, relationId, watermark, question, response, showPreview = 0 }) => {
  const [id] = useState(uuid4());
  const session = useSelector(selectCurrentUser);
  const companyTemplate = session.companyTemplate;
  return (
    <div className="flex flex-row">
      <InnerHTML
        html={`<div className="flex flex-row gap-x-4 pl-2"><div id=sw${id}div style="width: 428px;"><script>{(function(w, d, s, o, f, js, fjs){w[o]=w[o] || function (){(w[o].q=w[o].q || []).push(arguments);}; (js=d.createElement(s)), (fjs=d.getElementsByTagName(s)[0]); js.id=o; js.src=f; js.async=1; fjs.parentNode.insertBefore(js, fjs);})(window, document, "script", "sw${id}", "${
          process.env.REACT_APP_SOLO
        }/widget.js"); sw${id}("init",{element: document.getElementById("sw${id}div"), solo: 2, debug: false, mode: "dark", module: "preview", id: ${pageId}, recording: ${
          video.id
        }, salesrepId: ${video.salesrepId}, companyId: ${
          video.companyId
        }, relationship: ${relationId}, location: ${JSON.stringify(video.location)}, thumbnail: ${JSON.stringify(
          video.thumbnail
        )}, coordinates: ${JSON.stringify(video.coordinates)}, preview: ${JSON.stringify(video.preview)}, question: ${
          question ? JSON.stringify(question.name.trim()) : undefined
        }, answer: ${
          response ? JSON.stringify(response[question.name.trim()]) : undefined
        }, isMuted: 0, showPreview: ${showPreview}, showControls: 1, isGated: 0, launchStyle: 'PLAY', launchHoverColor: '${
          companyTemplate.buttonBgHoverColor
        }', launchCircleColor: '${companyTemplate.buttonBgColor}', launchTriangleColor: '${
          companyTemplate.bgColor
        }', watermark: ${watermark} });}</script></div>`}
      />
    </div>
  );
};

export default DemoContainer;
