import { Redirect, useLocation, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  useGetCompanyInfoQuery,
  useGetCompanyTemplateQuery,
  useGetLogoQuery,
  useGetRecordingQuery,
} from "../../feature/api/apiSlice";
import { ErrorView } from "../ErrorView";
import { PageSpinner } from "../PageSpinner";
import Header from "../Header";
import InnerHTML from "dangerously-set-html-content";
import Footer from "../Footer";
import queryString from "query-string";
import { uuid4 } from "@sentry/utils";

export const LinkEmbedPageContainer = () => {
  const [uuid, setUuid] = useState(uuid4());
  const location = useLocation();
  const { token, id, companyId, widgetType } = useParams();
  const { title, description } = queryString.parse(location.search);
  const [logo, setLogo] = useState(null);
  const [companyTemplate, setCompanyTemplate] = useState(null);
  const [style, setStyle] = useState();
  const websiteStyle = useGetCompanyTemplateQuery({ companyId: companyId });
  const companyLogo = useGetLogoQuery({ companyId: companyId });
  const companyInfo = useGetCompanyInfoQuery({ companyId: companyId });

  useEffect(() => {
    if (!companyLogo.isLoading && logo === null) {
      if (companyLogo.data.payload[0].recent_method === "url") {
        if (companyLogo.data.payload[0].logo_url !== null && companyLogo.data.payload[0].logo_url !== "") {
          setLogo(companyLogo.data.payload[0].logo_url);
        }
      }
      if (companyLogo.data.payload[0].recent_method === "image") {
        if (companyLogo.data.payload[0].logo_image !== null && companyLogo.data.payload[0].logo_image !== "") {
          setLogo(companyLogo.data.payload[0].logo_image);
        }
      }
    }
  }, [companyLogo, logo]);

  useEffect(() => {
    if (!websiteStyle.isLoading && companyTemplate === null) {
      const template = JSON.parse(websiteStyle.data.payload[0].website_style);
      setStyle({
        color: template.textColor !== "" ? template.textColor : "#000000",
        backgroundColor: template.color !== "" ? template.bgColor : "#FFFFFF",
      });
      setCompanyTemplate(template);
    }
  }, [companyTemplate, websiteStyle]);

  if (websiteStyle.isError || companyLogo.isError || companyInfo.isError) {
    return <ErrorView title="Error loading necessary information. Please reload page." data={""} />;
  }

  if (websiteStyle.isLoading || companyLogo.isLoading || companyInfo.isLoading) {
    return <PageSpinner />;
  }

  let DemoContainer = null;
  switch (widgetType) {
    case "solo": {
      DemoContainer = (
        <InnerHTML
          html={`<div className="flex flex-row gap-x-4 pl-2"><div id=sw${uuid}div><script>(function(w, d, s, o, f, js, fjs){w[o]=w[o] || function (){(w[o].q=w[o].q || []).push(arguments);}; (js=d.createElement(s)), (fjs=d.getElementsByTagName(s)[0]); js.id=o; js.src=f; js.async=1; fjs.parentNode.insertBefore(js, fjs);})(window, document, "script", "sw${uuid}", "${process.env.REACT_APP_SOLO}/widget.js"); sw${uuid}("init",{element: document.getElementById("sw${uuid}div"), solo: ${id}, debug: false, mode: "dark", module: "solo"});</script></div>`}
        />
      );
      break;
    }
    case "hero": {
      DemoContainer = (
        <InnerHTML
          html={`<div className="flex flex-row gap-x-4 pl-2"><div id=hw${uuid}div><script>(function(w, d, s, o, f, js, fjs){w[o]=w[o] || function (){(w[o].q=w[o].q || []).push(arguments);}; (js=d.createElement(s)), (fjs=d.getElementsByTagName(s)[0]); js.id=o; js.src=f; js.async=1; fjs.parentNode.insertBefore(js, fjs);})(window, document, "script", "hw${uuid}", "${process.env.REACT_APP_HERO}/widget.js"); hw${uuid}("init",{element: document.getElementById("hw${uuid}div"), hero: ${id}, debug: false, mode: "dark", module: "hero"});</script></div>`}
        />
      );
      break;
    }
    case "cyoa": {
      DemoContainer = (
        <InnerHTML
          html={`<div className="flex flex-row gap-x-4 pl-2"><div id=cw${uuid}div><script>(function(w, d, s, o, f, js, fjs){w[o]=w[o] || function (){(w[o].q=w[o].q || []).push(arguments);}; (js=d.createElement(s)), (fjs=d.getElementsByTagName(s)[0]); js.id=o; js.src=f; js.async=1; fjs.parentNode.insertBefore(js, fjs);})(window, document, "script", "cw${uuid}", "${process.env.REACT_APP_CYOA}/widget.js"); cw${uuid}("init",{element: document.getElementById("cw${uuid}div"), cyoa: ${id}, debug: false, mode: "dark", module: "cyoa"});</script></div>`}
        />
      );
      break;
    }

    default:
      break;
  }
  document.title = title;

  return (
    companyTemplate && (
      <div className="flex flex-col min-h-screen">
        <Header logo={logo} companyName={companyInfo.data.payload[0].name} companyTemplate={companyTemplate} />
        <div style={style} className="flex-grow pb-20">
          <div className="container max-w-[1440px] mx-auto">
            <div className="flex flex-col mx-[20px] md:mx-[120px]">
              <div className="flex flex-col-reverse md:flex-row h-full w-full mt-20 gap-x-10">
                <div className="flex flex-col md:w-[33%] h-full md:gap-y-4">
                  <h1 className="text-[24px] md:text-[36px] font-bold md:leading-[50.4px]">{title}</h1>
                  <p className="text-[14px] md:text-[18px] font-semibold md:leading-[35px] text-[#6C7E8F]">
                    {description}
                  </p>
                </div>
                <div className="w-full md:w-[66%] h-full gap-x-34">{DemoContainer}</div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  );
};
