import Footer from "../Footer";
import Header from "../Header";
import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import InnerHTML from "dangerously-set-html-content";
import { uuid4 } from "@sentry/utils";
import {
  useGetCompanyInfoQuery,
  useGetCompanyTemplateQuery,
  useGetLogoQuery,
  useGetRecordingQuery,
} from "../../feature/api/apiSlice";
import { ErrorView } from "../ErrorView";
import { PageSpinner } from "../PageSpinner";

export const RecordingContainer = () => {
  const [uniqueId, setUniqueId] = useState(uuid4());
  const { token, id, companyId } = useParams();
  const [logo, setLogo] = useState(null);
  const [companyTemplate, setCompanyTemplate] = useState(null);
  const websiteStyle = useGetCompanyTemplateQuery({ companyId: companyId });
  const companyLogo = useGetLogoQuery({ companyId: companyId });
  const companyInfo = useGetCompanyInfoQuery({ companyId: companyId });
  const recording = useGetRecordingQuery({
    recordingId: id,
    companyId: companyId,
    token: token,
  });

  useEffect(() => {
    if (!companyLogo.isLoading && logo === null) {
      if (companyLogo.data.payload[0].recent_method === "url") {
        if (companyLogo.data.payload[0].logo_url !== null && companyLogo.data.payload[0].logo_url !== "") {
          setLogo(companyLogo.data.payload[0].logo_url);
        }
      }
      if (companyLogo.data.payload[0].recent_method === "image") {
        if (companyLogo.data.payload[0].logo_image !== null && companyLogo.data.payload[0].logo_image !== "") {
          setLogo(companyLogo.data.payload[0].logo_image);
        }
      }
    }
  }, [companyLogo, logo]);

  useEffect(() => {
    if (!websiteStyle.isLoading && companyTemplate === null) {
      setCompanyTemplate(JSON.parse(websiteStyle.data.payload[0].website_style));
    }
  }, [companyTemplate, websiteStyle]);

  if (websiteStyle.isError || companyLogo.isError || companyInfo.isError || recording.isError) {
    return <ErrorView title="Error loading necessary information. Please reload page." data={""} />;
  }

  if (websiteStyle.isLoading || companyLogo.isLoading || companyInfo.isLoading || recording.isLoading) {
    return <PageSpinner />;
  }

  if (recording.data.status === "error") {
    return <Redirect to="/error/" />;
  }

  const style = {
    color: companyTemplate.textColor !== "" ? companyTemplate.textColor : "#000000",
    backgroundColor: companyTemplate.color !== "" ? companyTemplate.bgColor : "#FFFFFF",
  };

  document.title = recording.data.payload[0].title;

  return (
    companyTemplate && (
      <div className="flex flex-col min-h-screen">
        <Header logo={logo} companyName={companyInfo.data.payload[0].name} companyTemplate={companyTemplate} />
        <div style={style} className="flex-grow pb-20">
          <div className="container max-w-[1440px] mx-auto">
            <div className="flex flex-col mx-[120px]">
              <div className="flex flex-row h-full w-full mt-20 gap-x-10">
                <div className="flex flex-col w-[33%] h-full gap-y-4">
                  <h1 className="text-[36px] font-bold leading-[50.4px]">{recording.data.payload[0].title}</h1>
                  <p className="text-[18px] font-semibold leading-[35px] text-[#6C7E8F]">
                    {recording.data.payload[0].description}
                  </p>
                </div>
                <div className="w-[66%] h-full gap-x-34">
                  <InnerHTML
                    html={`<div className="flex flex-row gap-x-4 pl-2"><div id=sw${uniqueId}div><script>{(function(w, d, s, o, f, js, fjs){w[o]=w[o] || function (){(w[o].q=w[o].q || []).push(arguments);}; (js=d.createElement(s)), (fjs=d.getElementsByTagName(s)[0]); js.id=o; js.src=f; js.async=1; fjs.parentNode.insertBefore(js, fjs);})(window, document, "script", "sw${uniqueId}", "${
                      process.env.REACT_APP_SOLO
                    }/widget.js"); sw${uniqueId}("init",{element: document.getElementById("sw${uniqueId}div"), solo: 2, debug: false, mode: "dark", module: "gif", id: ${
                      recording.data.payload[0].id
                    }, recording: ${recording.data.payload[0].id}, salesrepId: ${
                      recording.data.payload[0].salesrepId
                    }, companyId: ${recording.data.payload[0].companyId}, location: ${JSON.stringify(
                      recording.data.payload[0].location
                    )}, coordinates: ${JSON.stringify(
                      recording.data.payload[0].coordinates
                    )}, thumbnail: ${JSON.stringify(recording.data.payload[0].thumbnail)}, preview: ${JSON.stringify(
                      recording.data.payload[0].preview
                    )}, isMuted: 0, showPreview: 1, showControls: 1, isGated: 0, launchStyle: 'PLAY', launchHoverColor: '${
                      companyTemplate.buttonBgHoverColor
                    }', launchCircleColor: '${companyTemplate.buttonBgColor}', launchTriangleColor: '${
                      companyTemplate.bgColor
                    }', watermark: ${companyInfo.data.payload[0].watermark} });}</script></div>`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  );
};
