import {useGetRelationQuery} from "../../feature/api/apiSlice";
import {PageSpinner} from "../PageSpinner";
import React from "react";
import {FormQueryContainer} from "./FormQueryContainer";
import {ProgressiveFormQueryContainer} from "./ProgressiveFormQueryContainer/ProgressiveFormQueryContainer";

import {ErrorView} from "../ErrorView";
import {useSelector} from "react-redux";
import {selectCurrentUser} from "../../feature/session/sessionSlice";


export const RelationQueryContainer = ({prospectId, toggleAllQuestionsAnswered}) => {
    const session = useSelector(selectCurrentUser);
    const {
        data,
        isLoading,
        isError,
        error
    } = useGetRelationQuery({prospectId: prospectId, pageId: session.pageId, salesRepId: session.salesRepId, companyId: session.companyId});
    if (isLoading) return <PageSpinner/>;
    if (isError) return <div>{JSON.stringify(error)}</div>;

    if (data.payload.length === 0) return <ErrorView title="You have no forms available" data={{message: "Come Back Later"}}/>

    const relation = data.payload[0];
    return <ProgressiveFormQueryContainer prospectId={prospectId}
                                          relationId={relation.id}
                                          salesRepId={session.salesRepId}
                                          readOnly={relation.status === "done"}
                                          toggleAllQuestionsAnswered={toggleAllQuestionsAnswered} />
}
