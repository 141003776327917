import WelcomeContainer from "./WelcomeContainer";
import Header from "../Header";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Footer from "../Footer";
import ReactDom from "react-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../feature/session/sessionSlice";
import {
  useCreateRelationshipMutation,
  useGetEmailsBlockListQuery,
  useGetLogoQuery,
  useSendMailMutation,
} from "../../feature/api/apiSlice";
import { PageSpinner } from "../PageSpinner";
import { CenteredView } from "../CenteredView";
import { ErrorView } from "../ErrorView";
import { Redirect } from "react-router-dom";
import {EmbedPageContainer} from "./EmbedPageContainer";

const MODAL_STYLES = {
  width: "500px",
  position: "fixed",
  top: "55%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#FFF",
  padding: "20px",
  marginTop: "6rem",
  borderRadius: "4px",
  zIndex: 1000,
};

const OVERLAY_STYLES = {
  position: "fixed",
  top: "50%",
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1000,
};

const TeaserModal = ({ open, children, onClose }) => {
  if (!open) return null;
  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLES} />
      <div style={MODAL_STYLES}>{children}</div>
    </>,
    document.getElementById("portal")
  );
};

const TeaserView = ({ session }) => {
  const pageTemplate = session.pageTemplate;
  const companyTemplate = session.companyTemplate;

  const [createRelationship] = useCreateRelationshipMutation();
  const [sendEmail] = useSendMailMutation();
  const getEmailsBlockList = useGetEmailsBlockListQuery({ companyId: session.companyId });
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [isOpen, onClose] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [corpEmails, setCorpEmails] = useState(["dispostable.com", "gmail.com", "hotmail.com", "yahoo.com"]);
  const [showInvalidEmailErrorMessage, toggleShowInvalidEmailErrorMessage] = useState(false);

  const buttonColor = companyTemplate.buttonBgColor !== "" ? companyTemplate.buttonBgColor : "#1E41AF";
  const buttonHoverColor = companyTemplate.buttonBgHoverColor !== "" ? companyTemplate.buttonBgHoverColor : "#1E3B8A";
  const [buttonBgColor, setButtonBgColor] = useState(buttonColor);

  const tickMarkSVG = (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32 0C49.672 0 64 14.3267 64 32C64 49.6733 49.672 64 32 64C14.3267 64 0 49.6733 0 32C0 14.3267 14.3267 0 32 0ZM41.9191 23.2525L28.6667 36.5049L22.0809 29.9191C21.2998 29.1381 20.0335 29.1381 19.2525 29.9191C18.4714 30.7002 18.4714 31.9665 19.2525 32.7475L27.2525 40.7475C28.0335 41.5286 29.2998 41.5286 30.0809 40.7475L44.7475 26.0809C45.5286 25.2998 45.5286 24.0335 44.7475 23.2525C43.9665 22.4714 42.7002 22.4714 41.9191 23.2525Z"
        fill="#1E41AF"
      />
    </svg>
  );

  useEffect(() => {
    if (!getEmailsBlockList.isLoading) {
      if (getEmailsBlockList.data.status === "success") {
        if (getEmailsBlockList.data.payload.length !== 0) {
          if (getEmailsBlockList.data.payload[0].email_block_list !== null) {
            console.log(getEmailsBlockList.data);
            setCorpEmails(JSON.parse(getEmailsBlockList.data.payload[0].email_block_list));
          }
        }
      }
    }
  }, [getEmailsBlockList.data]);

  const handleEmailSent = async () => {
    const domain = email.split("@")[1];
    if ((pageTemplate.corporateEmail || pageTemplate.corporateEmail === undefined) && corpEmails.includes(domain)) {
      toggleShowInvalidEmailErrorMessage(true);
      return;
    } else {
      toggleShowInvalidEmailErrorMessage(false);
      const id = toast.loading("Generating Link");
      try {
        const data = {
          name: name,
          email: email,
          pageId: session.pageId,
          salesRepId: session.salesRepId,
          companyId: session.companyId,
          dateCreated: new Date().toISOString().slice(0, 19).replace("T", " "),
        };
        const createURL = await createRelationship(data).unwrap();
        if (createURL.status === "success") {
          try {
            const emailStatus = await sendEmail({
              url: createURL.payload.url,
              name: name,
              email: email,
            }).unwrap();
            if (emailStatus.status === "success") {
              toast.dismiss(id);
              setIsEmailSent(true);
            } else {
              console.log(emailStatus);
              toast.dismiss(id);
              toast.error("Failed to send email");
            }
          } catch (err) {
            toast.error("Failed to send email");
          }
        } else {
          toast.dismiss(id);
          toast.error("Failed to send email");
        }
      } catch (err) {
        toast.dismiss(id);
        toast.error("Couldn't connect to database");
      }
    }
  };

  const styles = {
    color: companyTemplate.textColor !== "" ? companyTemplate.textColor : "#000000",
    backgroundColor: companyTemplate.color !== "" ? companyTemplate.bgColor : "#FFFFFF",
  };

  const buttonStyle = {
    backgroundColor: buttonBgColor,
  };

  let showContainer = null;
  switch (session.pageType) {
    case "customized": {
      showContainer = (
          <WelcomeContainer
              headline={pageTemplate.headline}
              welcomeMessage={welcomeMessage}
          />
      );
      break;
    }
    case "standard": {
      showContainer = (
          <WelcomeContainer
              headline={pageTemplate.headline}
              welcomeMessage={welcomeMessage}
          />
      );
      break;
    }
    case "embed": {
      showContainer = (
          <EmbedPageContainer session={session}/>
      );
      break;
    }
    default:
      break;
  }

  return (
    <div style={styles} className="flex flex-col min-h-screen">
      <Header logo={session.companyLogo} companyName={session.companyName} companyTemplate={companyTemplate} />
      <div className="container max-w-[1440px] mx-auto mb-6 flex-grow">
        <div className="flex flex-col mx-[120px]">
          {showContainer}
          <div className={(session.pageType === "embed") ? "hidden" : ""}>
            <div className="pb-16 border-[1px] rounded-lg">
              <div className="flex flex-col bg-gray-200">
                <div className="py-[14px] blur-md">
                  <p className="ml-10 font-NunitoSans font-bold text-lg">
                    Watch several demos -- the more you share about us, the better we can personalize the demos to your
                    needs.
                  </p>
                </div>

                <div className="rounded-b-lg rounded-b-lg border-[1px] pb-16 blur-md">
                  <div className="my-8">
                    <p className="ml-10 font-NunitoSans font-bold text-lg">1. What Dialer/VOIP system do you use?</p>
                  </div>
                </div>
                <div className="rounded-b-lg rounded-b-lg border-[1px] pb-16 blur-md">
                  <div className="my-8">
                    <p className="ml-10 font-NunitoSans font-bold text-lg">1. What Dialer/VOIP system do you use?</p>
                  </div>
                  <div className="px-[60px]">
                    <img
                      width={300}
                      src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fthenextdoor.org%2Fwp-content%2Fuploads%2F2016%2F11%2Fvideo_placeholder.jpg&f=1&nofb=1"
                      alt="Video Thumbnail"
                    />
                  </div>
                </div>
                <TeaserModal open={isOpen} onClose={onClose}>
                  {/* Email form */}
                  <div className={isEmailSent ? "hidden" : "px-3"}>
                    <div className="mb-8">
                      <h4 className="text-lg font-NunitoSans font-bold">
                        Interested in seeing a demo tailored to your needs?
                      </h4>
                      <p className="font-Nunito text-sm font-semibold">
                        Share us your corporate email and we will send you a private link.
                      </p>
                    </div>
                    <input
                      required={true}
                      className="py-3 px-4 w-full rounded border mb-2"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Enter your name"
                    />
                    <input
                      required={true}
                      className="py-3 px-4 w-full rounded border mb-2"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter your corporate email address"
                    />
                    <h2 className={showInvalidEmailErrorMessage ? "text-red-500 text-md font-semibold" : "hidden"}>
                      Please enter a valid corporate email address
                    </h2>
                    <div className="flex justify-end">
                      <button
                        type="button"
                        style={buttonStyle}
                        onMouseEnter={() => {
                          setButtonBgColor(buttonHoverColor);
                        }}
                        onMouseLeave={() => {
                          setButtonBgColor(buttonColor);
                        }}
                        onClick={() => {
                          if (!name) {
                            return toast.error("Please enter your name first.");
                          }

                          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
                            return toast.error("Please enter a valid email.");
                          }
                          handleEmailSent();
                        }}
                        className="px-6 py-[9px] ml-6 mt-8 text-sm font-bold font-NunitoSans bg-[#1E41AF] text-white rounded"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                  {/* Email Sent Screen */}
                  <div className={!isEmailSent && "hidden"}>
                    <div className="flex flex-col items-center">
                      <div className="my-4">{tickMarkSVG}</div>
                      <h4 className="text-bold text-2xl text-lg font-NunitoSans my-4">Great!</h4>
                      <p className="text-semibold text-center text-lg font-NunitoSans my-4 w-[275px]">
                        We have emailed you a link to your personalized demo.
                      </p>
                    </div>
                  </div>
                </TeaserModal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

const TeaserContainer = () => {
  const session = useSelector(selectCurrentUser);
  if (!session?.token) {
    return <Redirect to="/error/" />;
  }
  return <TeaserView session={session} />;
};

export default TeaserContainer;
