import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../feature/session/sessionSlice";
import React, { Fragment, useEffect, useState } from "react";
import { CenteredView } from "../CenteredView";
import Footer from "../Footer";
import Header from "../Header";
import { PageSpinner } from "../PageSpinner";
import { useHistory } from "react-router-dom";
import { CustomizedStandardPageContainer } from "./CustomizedStandardPageContainer";
import { EmbedPageContainer } from "./EmbedPageContainer";
import { Gating } from "../Gating";

const SessionView = ({ session }) => {
  const companyTemplate = session.companyTemplate;
  const pageTemplate = session.pageTemplate;
  const [isVerified, setIsVerified] = useState(false);
  const history = useHistory();
  const style = {
    color: companyTemplate.textColor !== "" ? companyTemplate.textColor : "#000000",
    backgroundColor: companyTemplate.color !== "" ? companyTemplate.bgColor : "#FFFFFF",
  };

  let showPageType = <PageSpinner />;

  switch (session.pageType) {
    case "customized":
      showPageType = <CustomizedStandardPageContainer session={session} />;
      break;
    case "standard":
      showPageType = <CustomizedStandardPageContainer session={session} />;
      break;
    case "embed":
      showPageType = <EmbedPageContainer session={session} />;
      break;
    default:
      history.push(`${process.env.REACT_APP_CLIENT_PROSPECT}/error/`);
  }

  const body = (
    <div style={style} className="flex-grow pb-20">
      <div className="container max-w-[1440px] mx-auto">{showPageType}</div>
    </div>
  );
  return (
    <div className="flex flex-col min-h-screen">
      <Header logo={session.companyLogo} companyName={session.companyName} companyTemplate={companyTemplate} />
      {pageTemplate?.settings?.isOutbound ? (
        isVerified ? (
          /** Enter when verification complete OR already verified i.e. returning user */
          body
        ) : (
          /** Enter when NOT verified */
          <Gating session={session} isVerified={isVerified} setIsVerified={setIsVerified} />
        )
      ) : (
        body
      )}
      <Footer />
    </div>
  );
};

export const SessionContainer = () => {
  const session = useSelector(selectCurrentUser);
  if (!session?.token) {
    return (
      <CenteredView>
        <p className="text-2xl">Not logged in</p>
      </CenteredView>
    );
  }
  return <SessionView session={session} />;
};
