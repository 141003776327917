import {applyOperation} from "./operations";
import {isUndefined, prepareInput} from "./ruleEngineUtils";
export const applyRules = (rules, data) => {
    const results = [];
    if (rules.length === 0) {
        return
    }

    rules.forEach((rule) => {
        const result = applyRule(rule, data);
        if (result !== null) {
            results.push(result);
        }
    })

    return results;
}

const applyRule = (rule, data) => {
    const variables = JSON.parse(JSON.stringify(rule.variables));
    let condition = JSON.parse(JSON.stringify(rule.condition));

    variables.forEach((it) => {
        const LHS = prepareInput(data[it.variable.trim()], it.type.trim(), "LHS"); //User Input
        const RHS = prepareInput(it.value, it.type.trim(), "RHS"); //Rule Input
        const result = (!isUndefined(LHS, RHS)) ? applyOperation(LHS, RHS, it.operation, it.type.trim()) : false;
        condition = condition.replace(it.expression, result);
    })

    console.log(condition);

    // eslint-disable-next-line no-eval
    if (eval(condition)) {
        return rule.result;
    } else {
        return null;
    }
}