import { createSlice } from '@reduxjs/toolkit'

const sessionSlice = createSlice({
    name: 'session',
    initialState: { id: null, name: null, email: null, token: null, companyTemplate: null, pageTemplate: null, emailBlockList: null, salesRepId: null, pageId: null, pageType: null, companyId: null, companyName: null, companyLogo: null, unlisted: false, isPreview: false, secondaryCompanyId: null },
    reducers: {
        setCredentials: (state, {payload: { id, name, email, token, salesRepId, pageId, companyId, companyName, unlisted}}) => {
            state.id = id
            state.name = name
            state.email = email
            state.token = token
            state.salesRepId = salesRepId
            state.pageId = pageId
            state.companyId = companyId
            state.companyName = companyName
            state.unlisted = unlisted

        },
        setPreview: (state, {payload: { isPreview, secondaryCompanyId }}) => {
          state.isPreview = isPreview;
          state.secondaryCompanyId = secondaryCompanyId;
        },
        setTemplates: (state, {payload: {companyTemplate, pageTemplate, pageType, pageIdentifiers, emailBlockList}}) => {
            state.companyTemplate = companyTemplate
            state.pageTemplate = pageTemplate
            state.pageType = pageType
            state.pageIdentifiers = pageIdentifiers
            state.emailBlockList = emailBlockList;
        },
        setLogo: (state, {payload: {companyLogo}}) => {
            state.companyLogo = companyLogo
        },
        setPageId: (state, {payload: {pageId}}) => {
          state.pageId = pageId
        },
    },
})

export const { setCredentials, setPreview, setTemplates, setLogo, setPageId } = sessionSlice.actions;

export default sessionSlice.reducer

export const selectCurrentUser = (state) => state.session