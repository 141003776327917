import footerLogo from "../assets/footer-logo.svg"

const Footer = () => (
    <footer className="fixed left-0 bottom-0 w-full">
        <div className="flex items-center justify-center h-[64px] bg-[#DBE8F6]">
            <a
                target="_blank"
                rel="noreferrer"
                href={"https://www.zeroshq.com/"}
                className="text-[12px] font-light font-NunitoSans text-[#222222]"
            >
                <div className={"flex flex-row items-center gap-x-[10px]"}>
                    <p>
                        Powered by
                    </p>
                    <img src={footerLogo} alt="logo"/>
                </div>
            </a>
        </div>
    </footer>
);

export default Footer;
