import {toast, ToastContainer} from "react-toastify";
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import {useShareVideoMutation} from "../feature/api/apiSlice";
import Modal from "./Modal";
import {useState} from "react";

export const ShareVideoForm = ({isOpen, setIsOpen, salesRepId, prospectId, videoId}) => {
    const [shareVideo, {}] = useShareVideoMutation();
    const [openUrl, setOpenUrl] = useState(false);
    const [generatedUrl, setGeneratedUrl] = useState(null);

    const shareVideoHandler = async (values) => {
        toast.loading("Generating Video Link");
        const body = {
            name: values.name,
            email: values.email,
            salesRepId: salesRepId,
            prospectId: prospectId,
            videoId: videoId
        };
        const result = await shareVideo(body).unwrap();
        if (result.status === "success") {
            toast.dismiss();
            toast.success("Video Shared Successfully");
            setIsOpen(false);
            setOpenUrl(true);
            setGeneratedUrl(result.payload.url);
        } else {
            toast.dismiss();
            toast.error("Error Sharing Video");
            setIsOpen(false);
        }
    }

    const initialValues = {
        name: "",
        email: "",
    };

    const linkGenSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        email: Yup.string().email().required("Email is required"),
    });

    return (
        <>
            <Modal open={openUrl} onClose={() => setOpenUrl(false)}>
                <form>
                    <div className="mb-3" controlId="formBasicEmail">
                        <label>Generated Link:</label>
                        <br/>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="link"
                            type="link"
                            name="link"
                            value={generatedUrl}
                            readOnly
                        />
                    </div>
                </form>
            </Modal>
            <Modal
                open={isOpen}
                onClose={() => {
                    setIsOpen(false);
                }}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={linkGenSchema}
                    onSubmit={(values) => shareVideoHandler(values)}
                >
                    {(formik) => {
                        const {isValid, dirty} = formik;
                        const classSubmit =
                            "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline";
                        return (
                            <div className="w-full max-w-xs mx-auto">
                                <Form className="bg-white">
                                    <div className="mb-4">
                                        <label
                                            className="block text-gray-700 text-sm font-bold mb-2"
                                            htmlFor="name"
                                        >
                                            Name
                                        </label>
                                        <Field
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="name"
                                            type="name"
                                            name="name"
                                            placeholder="Enter name"
                                        />
                                        <ErrorMessage
                                            name="name"
                                            component="span"
                                            className="text-red-500 text-xs italic"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label
                                            className="block text-gray-700 text-sm font-bold mb-2"
                                            htmlFor="email"
                                        >
                                            Email
                                        </label>
                                        <Field
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="email"
                                            type="email"
                                            name="email"
                                            placeholder="Enter email"
                                        />
                                        <ErrorMessage
                                            name="email"
                                            component="span"
                                            className="text-red-500 text-xs italic"
                                        />
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <button
                                            className={
                                                dirty && isValid
                                                    ? classSubmit
                                                    : `bg-gray-300 py-2 px-4 rounded disabled cursor-not-allowed`
                                            }
                                            type="submit"
                                            disabled={!(dirty && isValid)}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );
};
