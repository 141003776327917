import {Model, Survey} from "survey-react";
import React, {forwardRef, useEffect, useState} from "react";
import {RecommendationsContainer} from "../RecommendationsContainer";
import {useSendResponseMutation} from "../../../feature/api/apiSlice";
import {PageSpinner} from "../../PageSpinner";
import {toast} from "react-toastify";
import {StylesManager} from "survey-react";
import {useSelector} from "react-redux";
import {selectCurrentUser} from "../../../feature/session/sessionSlice";
import styled from "styled-components";

const StyledSurveyContainer = styled.div`
  .sv-radio__svg,
  .sv-checkbox__svg {
    outline: 1px solid ${({border}) => border} !important;
    border: 1px solid ${({border}) => border} !important;

  }

  .sv-dropdown {
    border: 2px solid ${({border}) => border} !important;
  }

  .sv-radio--checked .sv-radio__svg {
    outline: 1px solid ${({border}) => border} !important;
    background-color: ${({border}) => border} !important;
    border: 1px solid ${({border}) => border} !important;
    fill: ${({text}) => text} !important;
  }

  .sv-text {
    border: 2px solid ${({border}) => border} !important;
    outline: 0 !important;
    color: ${({text}) => text} !important;
    font-weight: 600 !important;
  }

  .sv-root-modern .sv-checkbox--checked .sv-checkbox__svg {
    background-color: ${({border}) => border} !important;
    fill: ${({text}) => text} !important;
    outline: 0 !important;
  }

  .sv-page.sv-body__page {
    margin-top: 20px !important;
  }
`;

export const IndivSurvey = forwardRef(({
                                           videoRef,
                                           data,
                                           index,
                                           revealQuestions,
                                           setRevealQuestions,
                                           form,
                                           savedRes,
                                           setSavedRes,
                                           prospectId,
                                           relationId,
                                           salesRepId,
                                           formId
                                       }, ref) => {

    const [sendResponse, {isLoading: isRespondBeingSend}] =
        useSendResponseMutation();
    const session = useSelector(selectCurrentUser);
    const companyTemplate = session.companyTemplate;
    const [surveyResponse, setSurveyResponse] = useState(null);
    // const [borderColor, setBorderColor] = useState(session.companyTemplate.borderColor);

    const buttonColor =
        companyTemplate.buttonBgColor !== ""
            ? companyTemplate.buttonBgColor
            : "#1E41AF";
    const buttonHoverColor =
        companyTemplate.buttonBgHoverColor !== ""
            ? companyTemplate.buttonBgHoverColor
            : "#1E3B8A";
    const [buttonBgColor, setButtonBgColor] = useState(buttonColor);
    const style = {
        backgroundColor: buttonBgColor,
    };

    StylesManager.ThemeColors["modern"]['$text-color'] = `${companyTemplate.textColor}`;
    StylesManager.applyTheme("modern");
    const survey = new Model({questions: [data]});

    useEffect(() => {
        setSurveyResponse(savedRes);
    }, [savedRes])

    if (isRespondBeingSend) return <PageSpinner text="Submitting..."/>;

    survey.onValueChanged.add((data) => {
        setSurveyResponse(data.valuesHash);
    });

    const handleOk = async () => {
        if (surveyResponse !== null) {
            if (!session.unlisted) {
                const responseStatus = await sendResponse({
                    prospectId: prospectId,
                    relationId: relationId,
                    formId: formId,
                    answers: JSON.stringify(surveyResponse),
                }).unwrap();
                if (responseStatus.status === "success") {
                    const questionArr = [...revealQuestions];
                    questionArr[index + 1] = true;
                    setRevealQuestions(questionArr);
                    setSavedRes(surveyResponse);
                } else {
                    toast.error("Error sending response to database");
                }
            } else {
                const questionArr = [...revealQuestions];
                questionArr[index + 1] = true;
                setRevealQuestions(questionArr);
                setSavedRes(surveyResponse);
            }
        } else {
            toast.error("Please select an option first");
        }

    }

    let responseObject = {};
    if (savedRes !== null) {
        for (let i = 0; i <= index; i++) {
            let key = form.questions[i].name.trim();
            responseObject[key] = savedRes[form.questions[i].name.trim()];
        }
    }

    const borderColor = session.companyTemplate.borderColor;
    const textColor = session.companyTemplate.textColor;

    return (
        <div style={{ borderColor }} className={(revealQuestions[index] ? (index === 0) ? "pb-[24px]" : "pt-[20px] pb-[24px] border-t" : "hidden")}>
            <div className="flex">
                <p className='mt-[24px] text-lg font-bold'>{index + 1}. </p>
                <StyledSurveyContainer text={textColor} border={borderColor}>
                    <Survey model={survey} data={surveyResponse} mode={''}
                            showNavigationButtons={'none'}
                            showQuestionNumbers={'false'}/>
                </StyledSurveyContainer>
            </div>
            <button style={style}
                    onMouseEnter={() => {
                        setButtonBgColor(buttonHoverColor);
                    }}
                    onMouseLeave={() => {
                        setButtonBgColor(buttonColor);
                    }}
                    onClick={() => {
                        handleOk();
                    }}
                    className="py-1 px-6 my-5 ml-4 text-lg font-NunitoSans font-bold inline-block rounded text-white">OK
            </button>
            <RecommendationsContainer
                ref={ref}
                videoRef={videoRef}
                question={data}
                index={index}
                prospectId={prospectId}
                relationId={relationId}
                salesRepId={salesRepId}
                response={responseObject}
            />
        </div>
    );
});