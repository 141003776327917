import {configureStore} from "@reduxjs/toolkit";
import {apiSlice} from "./feature/api/apiSlice";

import sessionReducers from "./feature/session/sessionSlice"

export default configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        session: sessionReducers
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiSlice.middleware)
});