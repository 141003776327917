import React, { useState } from "react";
import InnerHTML from "dangerously-set-html-content";
import { useGetAllRecommendationsQuery, useSendVideoEventMutation } from "../../feature/api/apiSlice";
import { useSelector } from "react-redux";
import { uuid4 } from "@sentry/utils";
import { selectCurrentUser } from "../../feature/session/sessionSlice";
import ReactPlayer from "react-player";
import { PageSpinner } from "../PageSpinner";
import { ShareVideoForm } from "../ShareVideoForm";

const getDateTime = () => new Date(Date.now()).toISOString().slice(0, 19).replace("T", " ");

const getTime = (seconds) => new Date(Math.round(seconds) * 1000).toISOString().substr(11, 8);

const VideoPlayer = ({ relationId, videoId, question, response, ...props }) => {
  const [progress, setProgress] = useState(0);
  const [sendVideoEvent] = useSendVideoEventMutation();
  const session = useSelector(selectCurrentUser);

  const sendEvent = async (type) => {
    console.log(`Dispatching ${type} video event`);
    const res = await sendVideoEvent({
      videoId,
      relationId,
      pageId: session.pageId,
      type,
      timestamp: getDateTime(),
      part: getTime(progress),
      module: "preview",
      question,
      response,
    });
    console.log(`Dispatching ${type} video event returned: ${JSON.stringify(res)}`);
  };

  return (
    <ReactPlayer
      width="428px"
      height="240px"
      onPlay={async () => await sendEvent("play")}
      onPause={async () => await sendEvent("pause")}
      onEnded={async () => await sendEvent("end")}
      onProgress={({ playedSeconds: progress }) => setProgress(progress)}
      {...props}
    />
  );
};

const Video = ({ video, relationId, salesRepId, prospectId, question, response }) => {
  const session = useSelector(selectCurrentUser);
  const companyTemplate = session.companyTemplate;
  const buttonColor = companyTemplate.buttonBgColor !== "" ? companyTemplate.buttonBgColor : "#1E41AF";
  const buttonHoverColor = companyTemplate.buttonBgHoverColor !== "" ? companyTemplate.buttonBgHoverColor : "#1E3B8A";
  const [buttonBgColor, setButtonBgColor] = useState(buttonColor);
  const style = {
    backgroundColor: buttonBgColor,
  };

  const [openForm, setOpenForm] = useState(false);
  return (
    <div className="flex flex-row gap-x-4 my-0 pl-2">
      <ShareVideoForm
        isOpen={openForm}
        setIsOpen={setOpenForm}
        prospectId={prospectId}
        salesRepId={salesRepId}
        videoId={video.id}
      />
      <VideoPlayer
        className="flex-shrink-0"
        controls
        light
        url={video.url}
        videoId={video.id}
        relationId={relationId}
        question={question}
        response={response}
      />
      <div className="flex flex-col ml-[20px] gap-y-2 max-w-[564px] font-NunitoSans">
        <h3
          style={{
            color: companyTemplate.textColor !== "" ? companyTemplate.textColor : "#000000",
          }}
          className="m-0 video-header"
        >
          {video.title}
        </h3>
        <p
          style={{
            color: companyTemplate.textColor !== "" ? companyTemplate.textColor : "#000000",
          }}
          className="m-0 video-des"
        >
          {video.description}
        </p>
        {/*<button*/}
        {/*  className="px-6 w-24 h-10 text-lg font-NunitoSans font-bold inline-block rounded text-white"*/}
        {/*  style={style}*/}
        {/*  onMouseEnter={() => {*/}
        {/*    setButtonBgColor(buttonHoverColor);*/}
        {/*  }}*/}
        {/*  onMouseLeave={() => {*/}
        {/*    setButtonBgColor(buttonColor);*/}
        {/*  }}*/}
        {/*  onClick={() => {*/}
        {/*    setOpenForm(true);*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Share*/}
        {/*</button>*/}
      </div>
    </div>
  );
};

export const StandardPreviewContainer = ({ relationId, prospectId, salesRepId }) => {
  const [uuid, setUuid] = useState(uuid4());
  const companyId = useSelector((state) => state.session.companyId);
  const session = useSelector(selectCurrentUser);
  const pageId = session.pageId;
  const { data, isLoading, isError, error } = useGetAllRecommendationsQuery({
    companyId: (session.isPreview) ? session.secondaryCompanyId : companyId,
  });

  if (isLoading) return <PageSpinner />;
  if (isError) return <div>{JSON.stringify(error)}</div>;
  const companyTemplate = session.companyTemplate;
  const textColor = session.companyTemplate.textColor;
  // const videosArr = Object.values(data.payload.videos);
  // const previewsArr = Object.values(data.payload.recordings);
  const videosAndRecordings = Object.values(data.payload.recordings);

  const standardPreviewVideos = session.pageTemplate.standardPreviewVideos;

  let standardPreview = [];
  if (standardPreviewVideos !== undefined) {
    standardPreview = standardPreviewVideos
      .map((video) => videosAndRecordings.filter((preview) => preview.id === video.value))
      .flat();
  }

  const borderColor = session.companyTemplate.borderColor;

  return (
    <>
      {standardPreview.length > 0 && (
        <div className="flex flex-col flex-wrap place-content-between gap-x-6">
          {standardPreview.map((video, index) =>
            video.url ? (
              <div key={index} className="mb-10">
                <Video
                  video={video}
                  relationId={relationId}
                  prospectId={prospectId}
                  salesRepId={salesRepId}
                  question={null}
                  response={null}
                />
              </div>
            ) : (
              <div key={index} style={{ borderColor }} className={`flex flex-col md:flex-row gap-y-[18px] gap-x-4 pl-2 py-20 ${(index !== standardPreview.length - 1) ? "border-b" : ""} w-full`}>
                <InnerHTML
                  html={`<div className="flex flex-row gap-x-4 pl-2"><div id=sw${uuid}div style="width: 428px;"><script>{(function(w, d, s, o, f, js, fjs){w[o]=w[o] || function (){(w[o].q=w[o].q || []).push(arguments);}; (js=d.createElement(s)), (fjs=d.getElementsByTagName(s)[0]); js.id=o; js.src=f; js.async=1; fjs.parentNode.insertBefore(js, fjs);})(window, document, "script", "sw${uuid}", "${
                    process.env.REACT_APP_SOLO
                  }/widget.js"); sw${uuid}("init",{element: document.getElementById("sw${uuid}div"), solo: 2, debug: false, mode: "dark", module: "preview", id: ${pageId}, recording: ${
                    video.id
                  }, salesrepId: ${video.salesrepId}, companyId: ${video.companyId}, location: ${JSON.stringify(
                    video.location
                  )}, relationship: ${relationId}, thumbnail: ${JSON.stringify(
                    video.thumbnail
                  )}, preview: ${JSON.stringify(
                    video.preview
                  )}, isMuted: 0, showPreview: 0, showControls: 1, isGated: 0, launchStyle: 'PLAY', launchHoverColor: '${
                    companyTemplate.buttonBgHoverColor
                  }', launchCircleColor: '${companyTemplate.buttonBgColor}', launchTriangleColor: '${
                    companyTemplate.bgColor
                  }'});}</script></div>`}
                />
                <div className="flex flex-col md:ml-[20px] gap-y-2 max-w-[564px]">
                  <h3 style={{ color: textColor }} className="m-0 video-header">
                    {video.title}
                  </h3>
                  <p style={{ color: textColor }} className="m-0 video-des">
                    {video.description}
                  </p>
                </div>
              </div>
            )
          )}
        </div>
      )}
    </>
  );
};
