import { Redirect, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import queryString from "query-string";
import InnerHTML from "dangerously-set-html-content";
import {
  useGetCompanyInfoQuery,
  useGetCompanyTemplateQuery,
  useGetLogoQuery,
  useGetPreviewQuery,
} from "../../feature/api/apiSlice";
import { ErrorView } from "../ErrorView";
import { PageSpinner } from "../PageSpinner";
import Header from "../Header";
import Footer from "../Footer";
import { uuid4 } from "@sentry/utils";

export const CYAPreviewContainer = () => {
  const [uuid, setUuid] = useState(uuid4());
  const location = useLocation();
  const [logo, setLogo] = useState(null);
  const [companyTemplate, setCompanyTemplate] = useState(null);
  const { data_id, template } = queryString.parse(location.search);
  const extractedId = data_id.slice(data_id.lastIndexOf("-") + 1); // Extract the digits after the last hyphen, discard rest of the data_id
  const websiteStyle = useGetCompanyTemplateQuery({ companyId: template });
  const [style, setStyle] = useState();
  const companyLogo = useGetLogoQuery({ companyId: template });
  const companyInfo = useGetCompanyInfoQuery({ companyId: template });
  const previewData = useGetPreviewQuery({ id: extractedId });

  useEffect(() => {
    if (!companyLogo.isLoading && logo === null) {
      if (companyLogo.data.payload[0].recent_method === "url") {
        if (companyLogo.data.payload[0].logo_url !== null && companyLogo.data.payload[0].logo_url !== "") {
          setLogo(companyLogo.data.payload[0].logo_url);
        }
      }
      if (companyLogo.data.payload[0].recent_method === "image") {
        if (companyLogo.data.payload[0].logo_image !== null && companyLogo.data.payload[0].logo_image !== "") {
          setLogo(companyLogo.data.payload[0].logo_image);
        }
      }
    }
  }, [companyLogo, logo]);

  useEffect(() => {
    if (!websiteStyle.isLoading && companyTemplate === null) {
      const template = JSON.parse(websiteStyle.data.payload[0].website_style);
      setStyle({
        color: template.textColor !== "" ? template.textColor : "#000000",
        backgroundColor: template.color !== "" ? template.bgColor : "#FFFFFF",
      });
      setCompanyTemplate(template);
    }
  }, [companyTemplate, websiteStyle]);

  if (websiteStyle.isError || companyLogo.isError || companyInfo.isError || previewData.isError) {
    return <ErrorView title="Error loading necessary information. Please reload page." data={""} />;
  }

  if (websiteStyle.isLoading || companyLogo.isLoading || companyInfo.isLoading || previewData.isLoading) {
    return <PageSpinner />;
  }

  if (previewData.data.payload === null || previewData.data.payload.length === 0) {
    return <Redirect to="/error/" />;
  }

  //use this for your data
  const preview = JSON.parse(previewData.data.payload[0].dump);
  const firstDemoinArr = preview.data.find((each) => each.episode === "demo");
  const recommendations = preview.recommendations
    ? preview.recommendations.show
      ? JSON.stringify(preview.recommendations.list)
      : "[]"
    : "[]";
  document.title = preview.name;
  console.log(preview);

  return (
    companyTemplate && (
      <div className="flex flex-col min-h-screen">
        <Header logo={logo} companyName={companyInfo.data.payload[0].name} companyTemplate={companyTemplate} />
        <div className="w-[60%] left-[50%] top-[50%] absolute translate-x-[-50%] translate-y-[-50%]">
          <InnerHTML
            html={`<div className="flex flex-row gap-x-4 pl-2"><div id=sw${uuid}div><script>{(function(w, d, s, o, f, js, fjs){w[o]=w[o] || function (){(w[o].q=w[o].q || []).push(arguments);}; (js=d.createElement(s)), (fjs=d.getElementsByTagName(s)[0]); js.id=o; js.src=f; js.async=1; fjs.parentNode.insertBefore(js, fjs);})(window, document, "script", "sw${uuid}", "${
              process.env.REACT_APP_SOLO
            }/widget.js"); sw${uuid}("init",{element: document.getElementById("sw${uuid}div"), solo: 2, debug: false, mode: "dark", module: "anonymous", isCyoa: ${true}, cyoaObj: ${JSON.stringify(
              preview.data
            )}, id: ${preview.id}, recording: ${firstDemoinArr.demo.value}, salesrepId: ${
              preview.salesrepId
            }, companyId: ${preview.companyId}, location: ${JSON.stringify(
              firstDemoinArr.demo.location
            )}, coordinates: ${JSON.stringify(firstDemoinArr.demo.coordinates)}, thumbnail: ${JSON.stringify(
              firstDemoinArr.demo.thumbnail
            )}, preview: ${JSON.stringify(firstDemoinArr.demo.preview)}, isMuted: ${!preview.setting[
              "audioOnLaunch"
            ]}, shouldLoop: ${false}, showPreview: ${preview.setting["playInline"]}, showControls: ${
              preview.setting["showControls"]
            }, isSneak: ${preview.setting["isSneak"]}, isCta: ${preview.setting["isCta"]}, ctaType: ${JSON.stringify(
              preview.setting["ctaType"]
            )}, ctaText: ${JSON.stringify(preview.setting["ctaText"])}, showWrapup: ${
              preview.setting["wrapUpScreen"]
            }, isGated: ${preview.setting["verifyIdentity"]}, showCustomThumbnail: ${
              preview.setting["showCustomThumbnail"]
            }, customThumbnail: ${JSON.stringify(preview.customThumbnail)}, redirect: ${
              preview.setting["redirectToRestrictedDomain"]
            }, redirectTo: ${JSON.stringify(preview.restrict)}, websiteStyle: ${
              websiteStyle.data.payload[0].website_style
            }, emailBlockList: ${websiteStyle.data.payload[0].email_block_list}, watermark: ${JSON.stringify(
              preview.flags.watermark
            )}, launchStyle: '${preview.setting["blinkerStyle"]}', launchSize: '${
              preview.setting["blinkerSize"]
            }', customLaunch: ${JSON.stringify(preview?.customBlinker)}, launchCircleColor: '${
              preview.setting["launchCircleColor"]
            }', launchHoverColor: '${preview.setting["launchHoverColor"]}', launchTriangleColor: '${
              preview.setting["launchTriangleColor"]
            }', launchHotspotColor: '${
              preview.setting["launchHotspotColor"]
            }', recommendations: ${recommendations}, redirectTo: ${JSON.stringify(
              preview.restrict
            )}, verificationContent: ${JSON.stringify(preview.verificationContent)}, emailRequired: ${
              preview.setting["isRequired"]
            }, customVerification: ${preview.setting["customVerification"]}, checkBlocklist: ${
              preview.setting["checkBlocklist"]
            }, checkDomain: ${preview.setting["checkDomain"]}, checkTwoFactor: ${
              preview.setting["checkTwoFactor"]
            }});}</script></div>`}
          />
        </div>
        <Footer />
      </div>
    )
  );
};
