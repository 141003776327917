import {useUnsubscribeSummaryWeeklyMutation} from "../../feature/api/apiSlice";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {PageSpinner} from "../PageSpinner";

export const WeeklySummaryUnsubContainer = () => {
    const [unsubsribe] = useUnsubscribeSummaryWeeklyMutation();
    const {id} = useParams();
    const [isUnsub, setIsUnsub] = useState(false);
    const [unsubSuccess, setUnsubSuccess] = useState(true);

    useEffect(() => {
        const unsub = async () => {
            const status = await unsubsribe({salesrepId: id}).unwrap();
            if (status.status === "success") {
                setIsUnsub(true);
            } else {
                setIsUnsub(true);
                setUnsubSuccess(false);
            }
        }
        unsub();
    }, [])

    if (!isUnsub) return <PageSpinner/>;

    return (
        <>
            {(unsubSuccess) ?
                <p className="text-black text-xl">
                    You have been unsubscribed successfully
                </p>
                :
                <p className="text-black text-xl">
                    An error occurred while unsubscribing. Please try again.
                </p>}
        </>
    )
}