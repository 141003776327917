import React, {useState} from "react";
import {uuid4} from "@sentry/utils";
import InnerHTML from "dangerously-set-html-content";
import {applyTemplate} from "../../libraries/template-engine/templateEngine";

export const EmbedPageContainer = ({session}) => {
    const [uuid] = useState(uuid4());
    const embedData = Array.isArray(session.pageTemplate?.embed) ? session.pageTemplate?.embed[0] : session.pageTemplate?.embed;
    let DemoContainer = null;
    switch ((embedData?.type)) {
        case "solo": {
            DemoContainer = (
                <InnerHTML
                    html={`<div className="flex flex-row gap-x-4 pl-2"><div id=sw${uuid}div><script>(function(w, d, s, o, f, js, fjs){w[o]=w[o] || function (){(w[o].q=w[o].q || []).push(arguments);}; (js=d.createElement(s)), (fjs=d.getElementsByTagName(s)[0]); js.id=o; js.src=f; js.async=1; fjs.parentNode.insertBefore(js, fjs);})(window, document, "script", "sw${uuid}", "${process.env.REACT_APP_SOLO}/widget.js"); sw${uuid}("init",{element: document.getElementById("sw${uuid}div"), solo: ${embedData?.id ?? null}, debug: false, mode: "dark", module: "solo"});</script></div>`}
                />
            );
            break;
        }
        case "hero": {
            DemoContainer = (
                <InnerHTML
                    html={`<div className="flex flex-row gap-x-4 pl-2"><div id=hw${uuid}div><script>(function(w, d, s, o, f, js, fjs){w[o]=w[o] || function (){(w[o].q=w[o].q || []).push(arguments);}; (js=d.createElement(s)), (fjs=d.getElementsByTagName(s)[0]); js.id=o; js.src=f; js.async=1; fjs.parentNode.insertBefore(js, fjs);})(window, document, "script", "hw${uuid}", "${process.env.REACT_APP_HERO}/widget.js"); hw${uuid}("init",{element: document.getElementById("hw${uuid}div"), hero: ${embedData?.id ?? null}, debug: false, mode: "dark", module: "hero"});</script></div>`}
                />
            );
            break;
        }
        case "cyoa": {
            DemoContainer = (
                <InnerHTML
                    html={`<div className="flex flex-row gap-x-4 pl-2"><div id=cw${uuid}div><script>(function(w, d, s, o, f, js, fjs){w[o]=w[o] || function (){(w[o].q=w[o].q || []).push(arguments);}; (js=d.createElement(s)), (fjs=d.getElementsByTagName(s)[0]); js.id=o; js.src=f; js.async=1; fjs.parentNode.insertBefore(js, fjs);})(window, document, "script", "cw${uuid}", "${process.env.REACT_APP_CYOA}/widget.js"); cw${uuid}("init",{element: document.getElementById("cw${uuid}div"), cyoa: ${embedData?.id ?? null}, debug: false, mode: "dark", module: "cyoa"});</script></div>`}
                />
            );
            break;
        }

        default:
            break;
    }

    return (
        <div className="flex flex-col min-h-[60vh]">
            <div style={{
                color: session.companyTemplate.textColor !== "" ? session.companyTemplate.textColor : "#000000",
                backgroundColor: session.companyTemplate.color !== "" ? session.companyTemplate.bgColor : "#FFFFFF",
            }} className="flex-grow pb-20">
                <div className="container max-w-[1440px] mx-auto">
                    <div className="flex flex-col mx-[20px] md:mx-[120px]">
                        <div className="flex flex-col-reverse md:flex-row h-full w-full mt-20 gap-x-10">
                            <div className="flex flex-col md:w-[33%] h-full md:gap-y-4">
                                <h1 className="text-[24px] md:text-[36px] font-bold md:leading-[50.4px]">{
                                    applyTemplate(session.pageTemplate?.title, session.pageIdentifiers)
                                }</h1>
                                <p className="text-[14px] md:text-[18px] font-semibold md:leading-[35px] text-[#6C7E8F]">{
                                    applyTemplate(session.pageTemplate?.description, session.pageIdentifiers)
                                }</p>
                            </div>
                            <div className="w-full md:w-[66%] h-full gap-x-34">{DemoContainer}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}