const Header = ({ logo, companyTemplate, companyName }) => <div className={(logo !== null) ? "flex" : "hidden"}>
    <div className="w-full" style={{backgroundColor: (companyTemplate.headerColor !== "") ? companyTemplate.headerColor : "FFF", color: (companyTemplate.textColor !== "") ? companyTemplate.textColor : "FFF",}}>
        <div className="container max-w-[1440px] mx-auto">
            <div className="flex flex-col md:flex-row items-center md:justify-start h-[96px] mx-[120px] my-[16px]">
                <div>
                    <img src={logo} style={{width: '100%'}} alt="Company Logo"/>
                </div>
                {companyTemplate.showCompanyName && <text className="text-2xl ml-[12px] font-bold">{companyName}</text>}
            </div>
        </div>
    </div>
</div>

export default Header;