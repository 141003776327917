import {
    useGetProspectNamesQuery,
    useGetRelationQuery,
    useGetSharedVideosQuery,
    useSendVideoEventMutation
} from "../../feature/api/apiSlice";
import {PageSpinner} from "../PageSpinner";
import ReactPlayer from "react-player";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {selectCurrentUser} from "../../feature/session/sessionSlice";
import {ShareVideoForm} from "../ShareVideoForm";
import {toast} from "react-toastify";
import {CenteredView} from "../CenteredView";

const getDateTime = () => new Date(Date.now())
    .toISOString()
    .slice(0, 19)
    .replace("T", " ")

const getTime = (seconds) => new Date(Math.round(seconds) * 1000)
    .toISOString()
    .substr(11, 8)

const VideoPlayer = ({relationId, videoId, ...props}) => {
    const [progress, setProgress] = useState(0)
    const [sendVideoEvent] = useSendVideoEventMutation()

    const sendEvent = async (type) => {
        console.log(`Dispatching ${type} video event`)
        const res = await sendVideoEvent({
            videoId, relationId, type, timestamp: getDateTime(), part: getTime(progress)
        })
        console.log(`Dispatching ${type} video event returned: ${JSON.stringify(res)}`)
    }

    return <ReactPlayer
        onPlay={async () => await sendEvent("play")}
        onPause={async () => await sendEvent("pause")}
        onEnded={async () => await sendEvent("end")}
        onProgress={({playedSeconds: progress}) => setProgress(progress)}
        {...props}/>
}

const Video = ({video, relationId, prospectId, salesRepId}) => {
    const {data, isLoading, isError} = useGetProspectNamesQuery({videoId: video.id, prospectId: prospectId});
    const [openForm, setOpenForm] = useState(false);
    if (isError) toast.error("Error Retrieving Videos");
    if (isLoading)
        return <PageSpinner/>;
    else {
        return <div className="flex flex-row gap-x-4">
            <ShareVideoForm
                isOpen={openForm}
                setIsOpen={setOpenForm}
                prospectId={prospectId}
                salesRepId={salesRepId}
                videoId={video.id}
            />
            <VideoPlayer className="flex-shrink-0" controls light
                         url={video.url}
                         videoId={video.id}
                         relationId={relationId}/>
            <div className="flex flex-col gap-y-2">
                <h3 className="text-xl m-0">{video.title}</h3>
                <p className="m-0">{video.description}</p>
                <p className="mt-2 font-bold">{"Forwarded by:"}</p>
                <p className="mb-2 font-bold">{data.payload.map((it, index) => {
                    let space = "";
                    if (data.payload.length - 1 !== index)
                        space = ", ";
                    return it.name + space;
                })}</p>
                <button
                    className="text-white bg-blue-800 hover:bg-blue-900 border-1 rounded font-medium w-24 h-8"
                    onClick={() => {
                        setOpenForm(true);
                    }}
                >
                    Share
                </button>
            </div>
        </div>
    }
}

export const SharedVideosContainer = () => {
    const session = useSelector(selectCurrentUser);
    const prospectId = session.id;
    const relationQuery = useGetRelationQuery(prospectId);
    const sharedVideosQuery = useGetSharedVideosQuery({prospectId: prospectId});

    if (!session?.token) {
        return <CenteredView><p className="text-2xl">Not logged in</p></CenteredView>
    } else {
        if (relationQuery.isLoading || sharedVideosQuery.isLoading) return <PageSpinner/>;
        if (relationQuery.isError || sharedVideosQuery.isError) return <div>{"Error getting videos"}</div>;

        return (
            <>
                <div className="flex mx-6 flex-col pt-4">
                    <h2 className="text-3xl">Welcome, {session.name}</h2>
                </div>
                <div className="flex mx-6 flex-col pt-4">
                    <h2 className="text-xl">Videos forwarded to you</h2>
                </div>
                <div className="flex flex-row flex-wrap place-content-between px-4 mt-8 gap-x-6 gap-y-4">
                    {sharedVideosQuery.data.payload.map((video, index) => (
                        <Video video={video} relationId={relationQuery.data.payload[0].id} prospectId={prospectId} salesRepId={relationQuery.data.payload[0].salesrepId}/>
                    ))}
                </div>
            </>
        )
    }
}