import React from "react";
import clsx from "clsx";
import styles from "./EmailInput.module.css";
// import Footer from "../../layout/footer/Footer";

const EmailInput = ({ verificationContent, emailRequired, email, setEmail, sendEmailVerification }) => {
  return (
    <div className={styles.card}>
      <div className={styles.content}>
        <h4 className={styles.title}>
          {verificationContent?.title?.length !== 0
            ? verificationContent?.title
            : "Enter your email address to continue"}
        </h4>
        <p className={styles.description}>
          {verificationContent?.description?.length !== 0
            ? verificationContent?.description
            : "Enter your email address below to access the content"}
        </p>
        <input
          className={styles.field}
          required={true}
          value={email ?? undefined}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          type="email"
          name="email"
          id="email"
          placeholder={verificationContent?.placeholder?.length !== 0 ? verificationContent?.placeholder : ""}
        />
        <div className={styles.privacy}>
          By pressing Submit, I understand that Zeros will process my personal information in accordance with their
          Privacy Policy. I may withdraw my consent through unsubscribe links at any time.
        </div>
        <div className={styles.controls}>
          {!emailRequired && (
            <button className={styles.link} onClick={() => sendEmailVerification(true)} type="button">
              Skip
            </button>
          )}
          <button className={styles.button} onClick={() => sendEmailVerification()} type="button">
            Submit
          </button>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default EmailInput;
