import React from "react";
import clsx from "clsx";
import local from "./EmailError.module.css";
// import Footer from "../../layout/footer/Footer";
// interface Props {
//   errorPrompt: boolean;
//   setErrorPrompt: (value: boolean) => void;
//   setIsOpen: (value: boolean) => void;
// }
const EmailError = ({ errorPrompt, setErrorPrompt }) => {
  return (
    <div className={local.card}>
      <div className={local.content}>
        <svg
          className={local.icon}
          width="66"
          height="54"
          viewBox="0 0 66 54"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.5 2.5H47.5C50.25 2.5 52.5 4.75 52.5 7.5V37.5C52.5 40.25 50.25 42.5 47.5 42.5H7.5C4.75 42.5 2.5 40.25 2.5 37.5V7.5C2.5 4.75 4.75 2.5 7.5 2.5Z"
            fill="#0071F2"
            stroke="#0071F2"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M47.5 7.5L27.5 25L7.5 7.5"
            stroke="white"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <circle cx="54" cy="42" r="12" fill="#D37474" />
          <path d="M59 37L49 47" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M49 37L59 47" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

        <p className={local.title}>Email address could not be verified</p>
        <p className={local.description}>
          Wrong Email? Please{" "}
          <span className={local.link} onClick={() => setErrorPrompt(false)}>
            re-enter
          </span>{" "}
          your email address
        </p>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default EmailError;
