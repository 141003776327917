import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {useSendProspectInfoMutation} from "../../feature/api/apiSlice";
import WelcomeContainer from "./WelcomeContainer";
import {RelationQueryContainer} from "./RelationQueryContainer";
import {applyTemplate} from "../../libraries/template-engine/templateEngine";

export const CustomizedStandardPageContainer = ({session}) => {
    const pageTemplate = session.pageTemplate;
    const companyTemplate = session.companyTemplate;
    const buttonColor =
        companyTemplate.buttonBgColor !== ""
            ? companyTemplate.buttonBgColor
            : "#1E41AF";
    const buttonHoverColor =
        companyTemplate.buttonBgHoverColor !== ""
            ? companyTemplate.buttonBgHoverColor
            : "#1E3B8A";
    const [buttonBgColor, setButtonBgColor] = useState(buttonColor);
    const [allQuestionsAnswered, toggleAllQuestionsAnswered] = useState(false);
    const contactFieldsKeys = []
    pageTemplate.contactFields.forEach(field => {
        contactFieldsKeys.push(field.fieldName);
    })
    const [contactInformationValues, setContactInformationValues] = useState(contactFieldsKeys.reduce((acc, curr) => (acc[curr] = '', acc), {}));

    const handleChangeContactInformation = ({target: {name, value}}) => {
        setContactInformationValues({
            ...contactInformationValues,
            [name]: value
        })
    }

    const checkRequiredFields = () => {
        let requiredFields = [];
        pageTemplate.contactFields.forEach(field => {
            if (field.required) {
                requiredFields.push(field.fieldName);
            }
        })
        let missingFields = [];
        requiredFields.forEach(field => {
            if (contactInformationValues[field] === "") {
                missingFields.push(field);
            }
        })
        if (missingFields.length > 0) {
            toast.error(`Please fill in the following required field: ${missingFields[0]}`);
            return false;
        } else {
            return true;
        }
    }
    const actionButtonStyle = {
        backgroundColor: buttonBgColor,
    };
    const borderColor = session.companyTemplate.borderColor;
    const formLabelColor = companyTemplate.buttonBgColor;
    const redirectToWebsite = () => {
        window.open(pageTemplate.redirectURL);
    };
    const [sendProspectInfo] = useSendProspectInfoMutation();
    const {id: prospectId, pageId, companyId} = session;
    return (
        <div className="flex flex-col mx-[120px]">
            <WelcomeContainer
                headline={pageTemplate.headline}
                welcomeMessage={pageTemplate?.welcomeMessage ?? ""}
            />
            <RelationQueryContainer
                toggleAllQuestionsAnswered={toggleAllQuestionsAnswered}
                prospectId={session.id}
            />
            <div
                className={(!allQuestionsAnswered && (session.pageType === "customized")) && "hidden"}>
                <div style={{borderColor}} className="rounded-lg border-[1.5px] mt-[40px] p-16">
                    <div className="flex flex-col md:flex-row gap-y-[18px] justify-center items-start">
                        <div className="md:w-1/2 w-full mr-[72px]">
                            {
                                (session.pageTemplate.contactInfoMessage === undefined || session.pageTemplate.contactInfoMessage === "")
                                    ?
                                    <p className="font-semibold text-lg">
                                                    <span style={{color: formLabelColor}}>
                                                      We are the leading platform
                                                    </span>{" "}
                                        that delivers modern, personalized Impressions™ at scale.
                                        Tell us a little more about yourself so{" "}
                                        <span style={{color: formLabelColor}}>
                                                      we can serve you better.
                                                    </span>
                                    </p>
                                    :
                                    <p className="font-semibold text-lg">{
                                        applyTemplate(session.pageTemplate.contactInfoMessage, session.pageIdentifiers)
                                    }</p>
                            }
                        </div>
                        <div className="md:w-1/2 w-full">
                            <p className="mb-6"><span className='text-red-500'>*</span> Required fields</p>
                            <div className="grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-6">
                                {pageTemplate.contactFields.map((formField, i) => {
                                        return (
                                            <input
                                                className="py-[13px] px-[16px] text-sm rounded border-[1.5px] w-full"
                                                key={i}
                                                name={formField.fieldName}
                                                type={formField.type}
                                                required={formField.required}
                                                onChange={handleChangeContactInformation}
                                                placeholder={formField.fieldName + `${formField.required ? " * " : ""}`}
                                            />
                                        )
                                    }
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-y-[18px] md:flex-row justify-between items-center mt-[18px]">
                        <h5 className="text-lg font-bold font-NunitoSans">{
                            applyTemplate(pageTemplate.actionMessage, session.pageIdentifiers)
                        }</h5>
                        <button
                            className="py-3.5 px-36 md:px-14 text-lg font-NunitoSans font-bold inline-block md:rounded rounded-[8px] bg-blue-800 hover:bg-blue-900 text-white"
                            type="submit"
                            onClick={async (e) => {
                                if (!checkRequiredFields()) {
                                    return;
                                }
                                const status = await sendProspectInfo({
                                    prospectId,
                                    pageId,
                                    companyId,
                                    info: JSON.stringify(contactInformationValues)
                                }).unwrap();
                                if (status.status === "success") {
                                    redirectToWebsite()
                                } else {
                                    toast.error("Error Encountered While Sending Information");
                                }
                            }}
                            style={actionButtonStyle}
                            onMouseEnter={() => {
                                setButtonBgColor(buttonHoverColor);
                            }}
                            onMouseLeave={() => {
                                setButtonBgColor(buttonColor);
                            }}
                        >
                            {
                                applyTemplate(pageTemplate?.actionButtonLabel ?? "Click Me!", session.pageIdentifiers)
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}