import React from "react";
import clsx from "clsx";
// import style from "../modal/Modal.css";
import local from "./EmailVerify.module.css";
// import Footer from "../../layout/footer/Footer";

// interface Props {
//   email: string | null;
//   getStatus: () => void;
//   setSentEmail: (state: boolean) => void;
// }
const EmailVerify = ({ email, setSentEmail, getStatus }) => {
  const emailSVG = (
    <svg width="55" height="45" viewBox="0 0 55 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3719_12698)">
        <path
          d="M7.5 2.5H47.5C50.25 2.5 52.5 4.75 52.5 7.5V37.5C52.5 40.25 50.25 42.5 47.5 42.5H7.5C4.75 42.5 2.5 40.25 2.5 37.5V7.5C2.5 4.75 4.75 2.5 7.5 2.5Z"
          fill="#0071F2"
          stroke="#0071F2"
          stroke-width="5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M47.5 7.5L27.5 25L7.5 7.5"
          stroke="white"
          stroke-width="5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3719_12698">
          <rect width="55" height="45" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  return (
    <div className={local.card}>
      <div className={local.content}>
        <div className={local.icon}>{emailSVG}</div>
        <div className={local.title}>Verify your email address</div>
        <hr className={local.horizontal} />
        <div className={local.description}>
          <p>
            Verification link emailed to <span style={{ color: "#222222" }}>{email}</span>.
          </p>
          <p>Click the link to confirm your address.</p>
          <p>Once you receive confirmation of verification, click below to start.</p>
        </div>
        <button className={local.button} onClick={getStatus} type="button">
          Start
        </button>
        <p className={local.return}>Can’t see the email? Check the spam folder.</p>
        <p className={local.return} style={{ marginBottom: "13px" }}>
          Wrong Email? Please{" "}
          <span className={local.link} onClick={() => setSentEmail(false)}>
            re-enter
          </span>{" "}
          your email address
        </p>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default EmailVerify;
