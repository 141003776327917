import {CenteredView} from "./CenteredView";
import React from "react";

export const ErrorView = ({title, data}) => (
    <CenteredView>
        <div className="flex flex-col gap-y-2 p-16">
            <h2 className="text-2xl text-center">{title}</h2>
            <p className="text-center">{data.message}</p>
            {data.payload && <p className="font-mono">{JSON.stringify(data.payload)}</p>}
        </div>
    </CenteredView>
)