export const checkInput = (template, data) => {
    return ((typeof template !== 'string' || typeof data !== 'object') ||
        (template === undefined || data === undefined) ||
        (!template || !data))
}

export const extractPlaceHolders = (template) => {
    const regex = /(?<=<)(.*?)(?=>)/g;
    const results = [];
    let matches = template.matchAll(regex);
    for (const match of matches) {
        if (match[0].trim().length > 0) results.push(match[0].trim());
    }
    return results;
}

export const replacePlaceHolders = (template, data, placeHolders) => {
    let newTemplate = template.replaceAll("<", "").replaceAll(">","");
    placeHolders.forEach(it => {
        if (data[it] !== undefined) newTemplate = newTemplate.replace(it, data[it]);
    })
    return newTemplate;
}
