import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetCompanyTemplateQuery,
  useGetLogoQuery,
  useGetCompanyInfoQuery,
  useSendWidgetTokenMutation,
} from "../feature/api/apiSlice";

import Header from "./Header";
import { PageSpinner } from "./PageSpinner";
import { ErrorView } from "./ErrorView";
import Footer from "./Footer";

const WidgetVerify = () => {
  const [countdown, setCountdown] = useState(30);
  const { companyId, token } = useParams();
  const [sendWidgetToken] = useSendWidgetTokenMutation();

  const sendToken = async (token) => {
    const res = await sendWidgetToken(token);
    return res;
  };

  useEffect(() => {
    const result = sendToken({ token });
  }, [token]);

  useEffect(() => {
    if (countdown > 0) {
      setTimeout(() => {
        setCountdown((prevCount) => prevCount - 1);
      }, 1000);
    }
  }, [countdown]);

  const companyTemplate = useGetCompanyTemplateQuery({
    companyId: companyId,
  });
  const companyInfo = useGetCompanyInfoQuery({ companyId: companyId });
  const companyLogo = useGetLogoQuery({ companyId: companyId });

  if (companyTemplate.isError || companyInfo.isError || companyLogo.isError) {
    return <ErrorView title="Error loading necessary information" data={""} />;
  }

  if (companyTemplate.isLoading || companyInfo.isLoading || companyLogo.isLoading) {
    return <PageSpinner />;
  }

  let companyLogoUsingMethod;
  if (companyLogo.data.payload[0].recent_method === "url") {
    if (companyLogo.data.payload[0].logo_url !== null && companyLogo.data.payload[0].logo_url !== "") {
      companyLogoUsingMethod = companyLogo.data.payload[0].logo_url;
    }
  }
  if (companyLogo.data.payload[0].recent_method === "image") {
    if (companyLogo.data.payload[0].logo_image !== null && companyLogo.data.payload[0].logo_image !== "") {
      companyLogoUsingMethod = companyLogo.data.payload[0].logo_image;
    }
  }

  let websiteStyle = JSON.parse(companyTemplate.data.payload[0].website_style);

  const styles = {
    color: websiteStyle.textColor !== "" ? websiteStyle.textColor : "#000000",
    backgroundColor: websiteStyle.color !== "" ? websiteStyle.bgColor : "#FFFFFF",
  };

  const onClose = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };

  return (
    <div style={styles} className="flex flex-col min-h-screen">
      <Header
        logo={companyLogoUsingMethod}
        companyName={companyInfo.data.payload[0].name}
        companyTemplate={websiteStyle}
      />
      <div className="flex flex-col flex-grow bg-white justify-center items-center">
        <svg width="97" height="80" viewBox="0 0 97 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.9993 3.66797H69.666C73.6993 3.66797 76.9993 6.96797 76.9993 11.0013V55.0013C76.9993 59.0346 73.6993 62.3346 69.666 62.3346H10.9993C6.96602 62.3346 3.66602 59.0346 3.66602 55.0013V11.0013C3.66602 6.96797 6.96602 3.66797 10.9993 3.66797Z"
            fill="#0071F2"
            stroke="#0071F2"
            stroke-width="7.33333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M69.6667 11L40.3333 36.6667L11 11"
            stroke="white"
            stroke-width="7.33333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <circle cx="79.1996" cy="61.6" r="17.6" fill="#74D37B" />
          <path
            d="M88.5032 54.5586L76.0575 67.0043L70.4004 61.3472"
            stroke="white"
            stroke-width="3.52"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div className="flex flex-col justify-start items-center rounded-lg w-3/4 h-1/4 p-4">
          <h2 className="text-black text-4xl leading-4 font-medium m-6">You are now verified</h2>
          <hr className="w-2/5 border border-[#D9D9D9] mt-2" />
          <p style={{ color: "#7D7D7D" }} className="font-normal text-2xl mt-4">
            Head back to the original tab.
          </p>
          <p style={{ color: "#7D7D7D" }} className="font-normal text-2xl mb-6">
            This tab will close in
          </p>
          {countdown !== 0 ? <p className="text-black text-7xl leading-4 font-normal m-6">{countdown}</p> : onClose()}
          <p style={{ color: "#000000" }} className="font-normal text-2xl m-3">
            seconds
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default WidgetVerify;
