import ReactPlayer from "react-player";
import { PageSpinner } from "../PageSpinner";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../feature/session/sessionSlice";
import { useGetCompanyInfoQuery, useGetIntroVideoQuery } from "../../feature/api/apiSlice";
import { useGetRelationQuery } from "../../feature/api/apiSlice";
import { useSendVideoEventMutation } from "../../feature/api/apiSlice";
import { useState } from "react";
import DemoContainer from "./DemoContainer";
import {applyTemplate} from "../../libraries/template-engine/templateEngine";

const getDateTime = () => new Date(Date.now()).toISOString().slice(0, 19).replace("T", " ");

const getTime = (seconds) => new Date(Math.round(seconds) * 1000).toISOString().substr(11, 8);

const VideoPlayer = ({ relationId, videoId, ...props }) => {
  const [progress, setProgress] = useState(0);
  const [sendVideoEvent] = useSendVideoEventMutation();
  const session = useSelector(selectCurrentUser);

  const sendEvent = async (type) => {
    console.log(`Dispatching ${type} video event`);
    const res = await sendVideoEvent({
      videoId,
      relationId,
      pageId: session.pageId,
      type,
      timestamp: getDateTime(),
      part: getTime(progress),
      module: "welcome",
    });
    console.log(`Dispatching ${type} video event returned: ${JSON.stringify(res)}`);
  };

  return (
    <ReactPlayer
      onPlay={async () => await sendEvent("play")}
      onPause={async () => await sendEvent("pause")}
      onEnded={async () => await sendEvent("end")}
      onProgress={({ playedSeconds: progress }) => setProgress(progress)}
      {...props}
    />
  );
};

const WelcomeContainer = ({ headline, welcomeMessage }) => {
  const session = useSelector(selectCurrentUser);
  const pageId = session.pageId;
  const { data, isLoading, isError, error } = useGetIntroVideoQuery({
    pageId: session.pageId,
    companyId: session.companyId,
  });
  const {
    data: relData,
    isLoading: relIsLoading,
    isError: relIsError,
    error: relError,
  } = useGetRelationQuery({
    prospectId: session.id,
    pageId: session.pageId,
    salesRepId: session.salesRepId,
    companyId: session.companyId,
  });
  const companyInfo = useGetCompanyInfoQuery({ companyId: (session.isPreview) ? session.secondaryCompanyId : session.companyId });
  if (isLoading || relIsLoading || companyInfo.isLoading) return <PageSpinner />;

  const introVideo = data.payload.start ?? null;
  document.title = headline;
  let preview = (session.pageTemplate.welcomePreview.id !== -1) ? (
    <DemoContainer
      video={introVideo}
      pageId={pageId}
      watermark={companyInfo.data.payload[0].watermark}
      relationId={relData.payload[0]?.id ?? undefined}
    />
  ) : null;
  return (
    <div className="flex flex-col-reverse md:flex-row md:justify-between space-x-32 gap-y-[18px] items-center mb-14 md:my-14">
      <div className="flex flex-col grow">
        <h2 className="text-4xl font-bold font-NunitoSans mb-4">{
          applyTemplate(headline, session.pageIdentifiers)
        }</h2>
        <p className="text-lg font-semibold font-NunitoSans">{
          applyTemplate(welcomeMessage, session.pageIdentifiers)
        }</p>
      </div>
      <div className="w-full">{preview}</div>
    </div>
  );
};

export default WelcomeContainer;
