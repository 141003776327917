import axios from "axios";
import React, { useState } from "react";

const UrlBasedVideo = () => {
  const [sent, setSent] = useState(false);
  const [checked, setChecked] = useState();
  const [comment, setComment] = useState();
  return (
    <div className="flex flex-col items-center justify-center m-5 gap-y-6 mb-20">
      <h1 className="text-[3rem] text-[#1E41AF]">Please verify if the following video works?</h1>
      <div className="bg-red-200 rounded-lg border border-red-800 p-2 text-red-800 text-center">
        Prior to testing, please ensure you have been granted access to your private network. <br />
        You may reload the page if needed.
      </div>
      <div className="w-3/4">
        <video controls src={`${process.env.REACT_APP_PROTOTYPE_URL}`}></video>
      </div>
      {sent ? (
        <div className="text-xl mt-5 text-green-800">Thank you!</div>
      ) : (
        <>
          <div className="text-xl mt-5 text-red-800">
            If you wish to notify us immediately, please fill the following:
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (checked === undefined) return;
              if (checked) {
                const headers = {
                  "Content-Type": "application/x-www-form-urlencoded",
                };
                axios
                  .post(
                    "https://hooks.slack.com/services/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",
                    JSON.stringify({
                      text: `Customer says the video is working. *Reason provided:* ${comment ?? "None"}`,
                    }),
                    { headers: headers }
                  )
                  .then((res) => {
                    setSent(true);
                    console.log(res);
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
              } else {
                const headers = {
                  "Content-Type": "application/x-www-form-urlencoded",
                };
                axios
                  .post(
                    "https://hooks.slack.com/services/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",
                    JSON.stringify({
                      text: `Customer says the video is *not* working. *Reason provided:* ${comment ?? "None"}`,
                    }),
                    { headers: headers }
                  )
                  .then((res) => {
                    setSent(true);
                    console.log(res);
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
              }
            }}
            className="flex flex-col"
          >
            <div className="flex flex-col justify-center items-center bg-[#E9E9E9] p-3 rounded">
              <div className="flex gap-x-4 items-center justify-between">
                <label className="text-lg font-bold">Is the video playing:</label>
                <span className="flex gap-x-2">
                  <input type="radio" name="check" id="yes" value={checked} onClick={(e) => setChecked(true)} />
                  <label htmlFor="check">Yes</label>
                </span>
                <span className="flex gap-x-2">
                  <input type="radio" name="check" id="no" value={!checked} onClick={(e) => setChecked(false)} />
                  <label htmlFor="check">No</label>
                </span>
              </div>
              <div className="flex gap-x-4 items-center mt-2">
                <label className="text-lg font-bold" htmlFor="comment">
                  Comments (if any):
                </label>
                <textarea
                  className="p-2"
                  name="comment"
                  id="comment"
                  cols="30"
                  rows="3"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                ></textarea>
              </div>
            </div>
            <input
              className={
                checked === undefined
                  ? "bg-[#D9D9D9] text-black font-bold cursor-not-allowed rounded p-2 mt-2"
                  : "bg-[#1E41AF] text-white font-bold cursor-pointer rounded p-2 mt-2"
              }
              type="submit"
              value="Send"
              disabled={checked === undefined}
            />
          </form>
        </>
      )}
    </div>
  );
};

export default UrlBasedVideo;
